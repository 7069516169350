import { object, string, Schema } from "yup";

import { UpdateOfferData } from "api";
import { OfferModel } from "models";

export const validationSchema = (accessKeyEnabled?: boolean): Schema<UpdateOfferData> => {
  if (accessKeyEnabled) {
    return object().shape({
      name: string().required("Name is required"),
      accessKey: string().required("Access key is required"),
    });
  }

  return object().shape({
    name: string().required("Name is required"),
    accessKey: string(),
  });
};

export const initialValues = (offer: OfferModel | null): UpdateOfferData => {
  if (offer?.optizmo) {
    return {
      name: offer?.name || "",
      accessKey: "",
    };
  }

  return {
    name: offer?.name || "",
  };
};
