import React, { FC, useState } from "react";
import { Button, Search, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";
import { useNavigate } from "react-router-dom";
import { useFetch } from "@hyper-fetch/react";

import { BusinessUnitModel } from "models";
import { useItemManagement, usePermissions, useSidebar, useTablePagination, useTour } from "hooks";
import { BreadcrumbsOption, Container, TableError, TableNoContent } from "components";
import { addItem, getInitialStorageFilters, getLinkPath, removeItem, updateItem } from "utils";
import { Plus } from "assets";
import { Navbar } from "components/layout/navbar/navbar";
import { BasePaginationQuery, BaseSearchQuery } from "types";
import { steps } from "./salesforce-business-units.tour";
import { BusinessUnitsResponse, getBusinessUnits } from "api";
import { initialFilters, TABLE_NAME, salesforceAccountsColumns } from "./salesforce-business-units.constants";
import { DeleteResponse } from "api/api.types";
import { SalesforceBusinessUnitItem } from "./table-item/salesforce-business-unit-item";
import {
  CreateDataExtension,
  CreateSalesforceBusinessUnit,
  UpdateSalesforceBusinessUnit,
} from "pages/integrations/salesforce";
import {
  ITERABLE_ACCOUNTS_LIST_PAGE,
  SALESFORCE_BUSINESS_UNITS_LIST_PAGE,
  SALESFORCE_DATA_EXTENSIONS_LIST_PAGE,
} from "constants/routes.constants";

import styles from "./salesforce-business-units.module.scss";

export const SalesforceBusinessUnitsPage: FC = () => {
  useTour({
    name: "salesforce-integrations",
    steps: steps,
  });

  const navigate = useNavigate();

  const { isMenuCollapsed, setMenuCollapsed } = useSidebar();
  const { create: createBusinessUnit } = useItemManagement<BusinessUnitModel>();
  const { update } = useItemManagement<BusinessUnitsResponse>();
  const { query, searchValue, setSearch } = useFilters<BaseSearchQuery & BasePaginationQuery>(
    getInitialStorageFilters<BaseSearchQuery & BasePaginationQuery>("salesforce", initialFilters),
  );

  const [canCreateAccount] = usePermissions("create", "accounts");

  const { data, loading, refetch, error, setData } = useFetch(
    getBusinessUnits.setQueryParams({ ...query, search: query.search }),
  );
  const pagination = usePagination({ listPayload: data });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  const [createdUnit, setCreatedUnit] = useState<BusinessUnitsResponse | null>(null);

  const handleEdit = (user: BusinessUnitsResponse) => {
    update.setItem(user);
  };

  const handleCreateBusinessUnit = (unit: BusinessUnitsResponse) => {
    if (data) addItem(unit, data, setData);
    setCreatedUnit(unit);
  };

  const handleUpdateBusinessUnit = (account: BusinessUnitsResponse) => {
    if (data) updateItem(account, data, setData);
  };

  const handleRemoveSuccess = (response: DeleteResponse) => {
    if (data) removeItem(response, data, setData);
  };

  const toggleSidebar = () => setMenuCollapsed(!isMenuCollapsed);

  const breadcrumbs: BreadcrumbsOption[] = [
    { name: "Salesforce business units", path: SALESFORCE_BUSINESS_UNITS_LIST_PAGE.path },
  ];

  return (
    <>
      <Navbar
        isMenuCollapsed={isMenuCollapsed}
        toggleSidebar={toggleSidebar}
        name="Salesforce business units"
        description="List of all Salesforce business units. Click on item to see all data extensions added to this account."
        navbarClassName="salesforce-navbar"
        breadcrumbs={breadcrumbs}
      />
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Search className={styles.search} searchValue={searchValue} setSearch={setSearch} />
            {canCreateAccount && (
              <Button onClick={createBusinessUnit.open} className="add-salesforce">
                <Plus /> Add Salesforce integration
              </Button>
            )}
          </div>
          <Table
            entityName={TABLE_NAME}
            columns={salesforceAccountsColumns}
            list={data?.data}
            error={error?.message}
            refresh={() => refetch()}
            loading={loading}
            resetColumnsOnMount={false}
            isTabTable
            customNoContent={<TableNoContent withBackground />}
            customError={<TableError description={error?.message} withBackground />}
            pagination={{
              ...pagination,
              elementsPerPage: currentElementsPerPage,
              onElementsPerPageChange: handlePerPageChange,
            }}
            row={(account, index) => (
              <SalesforceBusinessUnitItem
                index={index}
                businessUnit={account}
                handleEdit={handleEdit}
                onRemoveSuccess={handleRemoveSuccess}
                onActivateSuccess={handleUpdateBusinessUnit}
              />
            )}
          />
          <CreateSalesforceBusinessUnit
            isOpen={createBusinessUnit.isOpen}
            close={createBusinessUnit.close}
            refresh={refetch}
            onCreateSuccess={handleCreateBusinessUnit}
          />
          {createdUnit && (
            <CreateDataExtension
              isOpen={Boolean(createdUnit)}
              close={() => setCreatedUnit(null)}
              businessUnit={createdUnit}
              refresh={refetch}
              onCreateSuccess={() =>
                navigate(
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  getLinkPath(SALESFORCE_DATA_EXTENSIONS_LIST_PAGE.path, { businessUnitId: createdUnit?.id || "" }),
                )
              }
            />
          )}

          <UpdateSalesforceBusinessUnit
            businessUnit={update.item}
            isOpen={update.isOpen}
            close={update.close}
            onUpdateSuccess={handleUpdateBusinessUnit}
          />
        </div>
      </Container>
    </>
  );
};
