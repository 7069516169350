import { object, string, Schema, boolean } from "yup";

import { UpdateProjectData } from "api";
import { IterableProjectModel } from "models";

export const validationSchema = (apiKeyEnabled: boolean): Schema<UpdateProjectData> => {
  return object().shape({
    name: string().required("Name is required"),
    apiKey: apiKeyEnabled ? string().required("New API key is required") : string(),
    isActive: boolean(),
    enableUnforget: boolean(),
    enableComplaintsForwarding: boolean(),
  });
};

export const initialValues = (project: IterableProjectModel | null): UpdateProjectData => ({
  name: project?.name || "",
  apiKey: project?.apiKey || "",
  isActive: project?.isActive || false,
  enableUnforget: project?.enableUnforget || false,
  enableComplaintsForwarding: project?.enableComplaintsForwarding || false,
});
