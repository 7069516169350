import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";
import { CheckCircle, Failed, Circle, MinusCircle, ArrowRightGrey } from "assets";

import { HeiserLogo } from "assets/icons";

import styles from "./dashboard.module.scss";

const firstStep = (
  <div>
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "8px", marginBottom: "12px" }}>
      <HeiserLogo className={styles.logo} />
      <h2 style={{ margin: 0 }}>Welcome to Heiser!</h2>
    </div>
    <p>
      In just a few steps, you’ll be able to create and manage suppressions using lists from Iterable and Salesforce.
    </p>
    <p>
      Whether you’re looking to streamline your marketing efforts or improve data management, Heiser makes it simple and
      efficient.
    </p>
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "8px" }}>
      <ArrowRightGrey /> <span>Take this tour to get started</span>
    </div>
  </div>
);

const secondStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Heiser Dashboard page</h2>
    <p>
      The dashboard gives you a clear view of your current queues, showing the real-time progress of each suppression
      job, so you can stay up to date on what’s happening across your lists.
    </p>

    <p>
      You’ll also find detailed information in the suppression counters which are organized by projects and business
      units, giving you a granular look at how your suppressions are impacting each area of your operations.
    </p>
  </div>
);

const thirdStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>General section</h2>
    <p>This section provides a real-time updates on the status of your suppressions across Iterable and Salesforce.</p>
    <p>You can easily monitor the progress and health of your tasks. There are 5 counters for each possible status:</p>

    <div className={styles.statusesWrapper}>
      <div className={styles.completed}>
        <CheckCircle /> Succeeded
      </div>
      <div className={styles.failed}>
        <Failed /> Failed
      </div>
      <div className={styles.completed}>
        <Circle /> Active
      </div>
      <div className={styles.waiting}>
        <Circle /> Waiting
      </div>
      <div className={styles.waiting}>
        <MinusCircle /> Delayed
      </div>
    </div>
  </div>
);

const fourthStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Suppressions statuses</h2>
    <p>
      You can track the progress of your suppression tasks across both Iterable projects and Salesforce data extensions.
      This section is designed to give you a detailed breakdown of all tasks based on their current status.
    </p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    permission: ["read", "dashboard"],
  },
  {
    text: renderToString(secondStep),
    attachTo: { element: ".dashboard-navbar", on: "bottom-start" },
    permission: ["read", "dashboard"],
  },
  {
    text: renderToString(thirdStep),
    attachTo: { element: ".dashboard-general", on: "bottom" },
    permission: ["read", "dashboard"],
  },
  {
    text: renderToString(fourthStep),
    attachTo: { element: ".dashboard-suppressions-statuses", on: "top" },
    permission: ["read", "jobs"],
  },
];
