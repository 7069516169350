import { object, string, Schema } from "yup";

import { CreateOptizmoClientFormData } from "./create-optizmo-client.types";

export const validationSchema: Schema<CreateOptizmoClientFormData> = object().shape({
  name: string().required("Name is required"),
});

export const initialValues: CreateOptizmoClientFormData = {
  name: "",
};
