import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Account details</h2>
    <p>
      The Iterable Account Details page allows admins to manage all projects associated with a specific Iterable
      account.
    </p>
    <p>
      From here, you can control the status of each project and fine-tune its suppression settings. This view serves as
      the central hub for overseeing and adjusting how this Iterable account interacts with suppression workflows.
    </p>
  </div>
);

const secondStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Account activate toggle switch</h2>
    <p>
      At the top of the page, you’ll find a switch to enable or disable the entire Iterable account. When the account is
      toggled off, all associated projects will be disabled, and the account will be excluded from any suppression
      tasks.
    </p>
  </div>
);

const thirdStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Create project button</h2>
    <p>
      The Create Project button allows admins to add new projects to the Iterable account. By clicking this, you can
      quickly set up new projects that will be included in suppression activities, ensuring that your lists remain up to
      date.
    </p>
  </div>
);

const fourthStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Account projects list</h2>
    <p>
      The list of projects displays all active and inactive projects linked to the Iterable account. Each project comes
      with three control switches:
    </p>

    <ul>
      <li>
        The first switch toggles the project’s active status, determining whether it will be included in suppressions.
      </li>
      <li>
        The second switch enables or disables the &quot;unforgetting&quot; feature, which allows emails to be re-added
        to lists after 90 days, provided there have been no complaints during that period.
      </li>
      <li>
        The third switch controls automatic email forwarding to Salesforce in case of a complaint, helping you stay on
        top of user feedback.
      </li>
    </ul>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".iterable-details-navbar", on: "bottom-start" },
    permission: ["read", "integrations"],
  },
  {
    text: renderToString(secondStep),
    attachTo: { element: ".active-switch", on: "top" },
    permission: ["read", "integrations"],
  },
  {
    text: renderToString(thirdStep),
    attachTo: { element: ".add-project", on: "top" },
    permission: ["create", "integrations"],
  },
  {
    text: renderToString(fourthStep),
    attachTo: { element: ".table", on: "top" },
    permission: ["read", "integrations"],
  },
];
