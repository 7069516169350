import { FC } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form } from "formik";
import { Button, MessageField, FormButtons, FormField, notification, Modal } from "@epcnetwork/core-ui-kit";

import { updateClient, UpdateCompanyData } from "api";
import { UpdateOptizmoClientProps } from "./update-optizmo-client.types";
import { validationSchema, initialValues } from "./update-optizmo-client.constants";

import styles from "../update-optizmo.module.scss";

export const UpdateOptizmoClient: FC<UpdateOptizmoClientProps> = ({ isOpen, client, close, onUpdateSuccess }) => {
  const { submit, error, onSubmitSuccess, onSubmitError } = useSubmit(updateClient);
  onSubmitSuccess(({ response }) => {
    onUpdateSuccess(response);
    close();
    notification.success("Optizmo client updated!", "Client has been successfully updated.");
  });
  onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the client");
  });

  const handleSubmit = async (values: UpdateCompanyData) => {
    if (client) await submit({ data: values, params: { clientId: client.id } });
  };

  return (
    <Modal isOpen={isOpen} setClose={close}>
      <Formik
        initialValues={initialValues(client)}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        <Form>
          <div className={styles.title}>Update {client?.name} client</div>
          <MessageField message={error?.message || ""} />
          <FormField type="text" name="name" label="Client name" disableFloatingLabel required />

          <FormButtons className={styles.buttons}>
            <Button appearance="secondary" onClick={close}>
              Cancel
            </Button>
            <Button type="submit">Submit</Button>
          </FormButtons>
        </Form>
      </Formik>
    </Modal>
  );
};
