import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Business unit details</h2>
    <p>
      The Salesforce Business Unit Details page gives admins control over all the data extensions linked to a particular
      Salesforce business unit.
    </p>
    <p>
      This view allows you to manage the status of each data extension, making it easy to fine-tune how this business
      unit interacts with suppression workflows
    </p>
  </div>
);

const secondStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Business unit activate toggle switch</h2>
    <p>
      At the top of the page, there is a toggle switch to enable or disable the entire Salesforce business unit.
      Disabling the unit will deactivate all associated data extensions, ensuring that the business unit is excluded
      from future suppression processes.
    </p>
  </div>
);

const thirdStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Create data extension button</h2>
    <p>
      This button allows admins to add new data extensions to the Salesforce business unit. This feature ensures that
      new segments can be included in the suppression tasks, keeping your data management efficient and flexible.
    </p>
  </div>
);

const fourthStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Data extensions list</h2>
    <p>The list of data extensions provides a detailed view of all linked data extensions under this business unit.</p>

    <p>
      Each data extension comes with a single active toggle switch. This switch allows admins to control whether the
      data extension is currently included in the suppression process, ensuring fine-grained control over which segments
      are active or inactive at any given time.
    </p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".salesforce-details-navbar", on: "bottom-start" },
    permission: ["read", "integrations"],
  },
  {
    text: renderToString(secondStep),
    attachTo: { element: ".active-switch", on: "top" },
    permission: ["read", "integrations"],
  },
  {
    text: renderToString(thirdStep),
    attachTo: { element: ".add-data-extension", on: "top" },
    permission: ["create", "integrations"],
  },
  {
    text: renderToString(fourthStep),
    attachTo: { element: ".table", on: "top" },
    permission: ["read", "integrations"],
  },
];
