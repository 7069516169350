import { FC } from "react";
import { useLocalStorage } from "@epcnetwork/core-ui-kit";
import { useAbility } from "@casl/react";

import { PermissionTuple } from "models";
import { PermissionAbilityCtx, usePermissionCheck, usePermissions, useSidebar } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import { links } from "./menu.constants";
import { Link } from "../link/link";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";

import styles from "components/layout/sidebar/sidebar.module.scss";

type MenuProps = {
  closeSidebarMobile: () => void;
};

export const Menu: FC<MenuProps> = ({ closeSidebarMobile }) => {
  const { checkPermissionTuple } = usePermissionCheck();
  // const { width } = useWindowSize();
  // const { isMobileDevice } = useDevice();
  const { value } = useLocalStorage<boolean>(STORAGE_FIELDS.sidebarStatus);

  const permittedLinks = links.filter((link) => {
    const { permission } = link;

    if (permission) return checkPermissionTuple(permission);
    return true;
  });

  return (
    <div className={styles.menu}>
      {permittedLinks.map((link) => {
        if (link?.subMenu) {
          return (
            <div key={link.path}>
              <div className={styles.subMenuTrigger}>
                {link?.icon}
                {link.name}
              </div>

              <div className={styles.subMenuWrapper} style={{ marginLeft: value ? "0" : "20px" }}>
                {link.subMenu.map((item) => (
                  <div key={item.name} onClick={closeSidebarMobile} className={styles.subMenuItem}>
                    <Link {...item} className={item?.className} />
                  </div>
                ))}
              </div>
            </div>
          );
        }

        return (
          // TODO: sidebar doesn't close if 'closeSidebarMobile' added to 'Link' directly
          <span key={link.name} onClick={closeSidebarMobile}>
            <Link {...link} className={link?.className} />
          </span>
        );
      })}
    </div>
  );
};
