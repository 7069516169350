import { object, string, Schema } from "yup";

import { UpdateCompanyData } from "api";
import { CompanyModel } from "models";

export const validationSchema = (tokenEnabled?: boolean): Schema<UpdateCompanyData> => {
  if (tokenEnabled) {
    return object().shape({
      name: string().required("Name is required"),
      optizmoToken: string().required("New Optizmo token is required"),
    });
  }

  return object().shape({
    name: string().required("Name is required"),
    optizmoToken: string(),
  });
};

export const initialValues = (company: CompanyModel | null): UpdateCompanyData => ({
  name: company?.name || "",
  optizmoToken: company?.optizmoToken || "",
});
