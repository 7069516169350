import React, { useState } from "react";
import { Container } from "@epcnetwork/core-ui-kit";
import { useFetch } from "@hyper-fetch/react";
import { useDidUpdate } from "@better-hooks/lifecycle";
import cn from "classnames";

import { SuppressionsStatus } from "./suppressions-status/suppressions-status";
import { steps } from "./dashboard.tour";
import { InfoBox } from "pages/dashboard/info-box/info-box";
import { Accounts } from "assets";
import { getDashboardGeneral } from "api";
import { DashboardGeneralDataState } from "api/dashboard/dashboard.types";
import { DashboardGeneralData, DashboardListenerEventsKeys, useDashboardSocket, useTour } from "hooks";

import styles from "./dashboard.module.scss";

export const DashboardPage: React.FC = () => {
  useTour({
    name: "dashboard",
    steps,
  });

  const { socket } = useDashboardSocket();

  const [generalData, setGeneralData] = useState<DashboardGeneralDataState | null>(null);

  const { loading, onSuccess } = useFetch(getDashboardGeneral);
  onSuccess(({ response }) => {
    const iterable: Record<string, number> = {};
    const salesforce: Record<string, number> = {};

    Object.entries(response.iterable).forEach(([key, value]) => (iterable[key] = +value));
    Object.entries(response.salesforce).forEach(([key, value]) => (salesforce[key] = +value));

    setGeneralData({ iterable, salesforce } as DashboardGeneralDataState);
  });

  useDidUpdate(
    () => {
      const handleGeneralDataUpdate = ({ type, oldState, newState }: DashboardGeneralData) => {
        setGeneralData((previousData) => {
          if (previousData) {
            const updatedState = oldState
              ? {
                  ...previousData[type],
                  [oldState]:
                    +previousData[type][oldState] > 0
                      ? +previousData[type][oldState] - 1
                      : previousData[type][oldState],
                  [newState]: previousData[type][newState] + 1,
                }
              : { ...previousData[type], [newState]: previousData[type][newState] + 1 };

            return { ...previousData, [type]: updatedState };
          }

          return previousData;
        });
      };

      if (socket) {
        socket.on<DashboardListenerEventsKeys>("dashboardGeneralUpdate", handleGeneralDataUpdate);

        return () => {
          socket.off<DashboardListenerEventsKeys>("dashboardGeneralUpdate", handleGeneralDataUpdate);
        };
      }
    },
    [socket],
    true,
  );

  return (
    <>
      <Container contentClassName={styles.container}>
        <div className={cn(styles.boxContainer, "dashboard-general")}>
          <InfoBox
            integration="iterable"
            completed={generalData ? generalData.iterable.completed : "-"}
            failed={generalData ? generalData.iterable.failed : "-"}
            icon={<Accounts />}
            description="in the last 24 hours"
            className={styles.iterableBox}
            valuesMode="processed"
            loading={loading}
          />
          <InfoBox
            integration="iterable"
            active={generalData ? generalData.iterable.active : "-"}
            delayed={generalData ? generalData.iterable.delayed : "-"}
            pending={generalData ? generalData.iterable.waiting : "-"}
            icon={<Accounts />}
            description="currently"
            className={styles.delayedIterableBox}
            valuesMode="currently"
            loading={loading}
          />
          <InfoBox
            integration="salesforce"
            completed={generalData ? generalData.salesforce.completed : "-"}
            failed={generalData ? generalData.salesforce.failed : "-"}
            icon={<Accounts />}
            description="in the last 24 hours"
            className={styles.salesforceBox}
            valuesMode="processed"
            loading={loading}
          />
          <InfoBox
            integration="salesforce"
            active={generalData ? generalData.salesforce.active : "-"}
            delayed={generalData ? generalData.salesforce.delayed : "-"}
            pending={generalData ? generalData.salesforce.waiting : "-"}
            icon={<Accounts />}
            description="currently"
            className={styles.delayedSalesforceBox}
            valuesMode="currently"
            loading={loading}
          />
        </div>
        <div className={styles.content}>
          <SuppressionsStatus socket={socket} />
        </div>
      </Container>
    </>
  );
};
