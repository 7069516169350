import React, { useRef } from "react";
import AuthCode from "react-auth-code-input";
import { Loader } from "@epcnetwork/core-ui-kit";

import { ServerErrorType } from "api/api.types";
import { CrossCircle } from "assets";

import styles from "./otp-input.module.scss";

interface Props {
  onSubmit: (value: string) => void;
  submitting: boolean;
  error?: ServerErrorType | string | null;
}

export const OtpInput: React.FC<Props> = ({ onSubmit, submitting, error }) => {
  const handleOnChange = (value: string) => {
    if (value.length === 6) onSubmit(value);
  };

  return (
    <div className={styles.wrapper}>
      <AuthCode
        onChange={handleOnChange}
        containerClassName={styles.container}
        inputClassName={styles.input}
        disabled={submitting}
        autoFocus
      />
      {submitting && (
        <div className={styles.loading}>
          <Loader size={18} type="clip-loader" />
          <span>Loading...</span>
        </div>
      )}

      {error && !submitting && (
        <div className={styles.error}>
          <CrossCircle />
          <span>Incorrect MFA code</span>
        </div>
      )}
    </div>
  );
};
