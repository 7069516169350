import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, notification } from "@epcnetwork/core-ui-kit";
import { useSubmit } from "@hyper-fetch/react";

import { RootState, setUser } from "store";
import { QrModal } from "./qr-modal/qr-modal";
import { generateMfaUrl, getMe } from "api";
import { CheckCircle, CrossCircle } from "assets";

import styles from "./mfa.module.scss";

export const Mfa: React.FC = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.auth);

  const [authenticationUrl, setAuthenticationUrl] = useState<string>("");

  const getCurrentUser = useSubmit(getMe);
  getCurrentUser.onSubmitSuccess(({ response }) => {
    dispatch(setUser(response));
  });

  const refreshUserData = async () => {
    await getCurrentUser.submit();
  };

  const enableMfa = useSubmit(generateMfaUrl);
  enableMfa.onSubmitSuccess(({ response }) => {
    setAuthenticationUrl(response);
  });
  enableMfa.onSubmitError(() => {
    notification.error("Enable multi-factor authentication error", "Could not enable multi-factor authentication");
  });

  const isQrModalOpen = Boolean(authenticationUrl);
  const handleQrModalClose = () => setAuthenticationUrl("");

  const handleEnableClick = async () => {
    await enableMfa.submit();
  };

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Multi-factor authentication</h1>
      <p className={styles.description}>
        Use an authenticator app to enable multi-factor authentication for your account (e.g. Google Authenticator).
      </p>

      {user?.isMfaEnabled && (
        <div>
          <div className={styles.enabled}>
            <CheckCircle />
            <div>
              <p>Multi-factor authentication is already enabled.</p>
            </div>
          </div>
        </div>
      )}

      {!user?.isMfaEnabled && (
        <div>
          <div className={styles.disabled}>
            <CrossCircle />
            <div>
              <p>Multi-factor authentication is not enabled.</p>

              <span>Click the button below to enable multi-factor authentication for your account.</span>
            </div>
          </div>
          <Button onClick={handleEnableClick}>
            <CheckCircle />
            Enable multi-factor authentication
          </Button>
        </div>
      )}

      {Boolean(authenticationUrl) && (
        <QrModal
          isOpen={isQrModalOpen}
          handleClose={handleQrModalClose}
          qr={authenticationUrl}
          onSuccess={refreshUserData}
        />
      )}
    </div>
  );
};
