import { FC, useMemo, useState } from "react";
import { MessageField, NoContent, Switch, useDidUpdate, SelectOption } from "@epcnetwork/core-ui-kit";

import { isValidEmail } from "utils";
import { FileHeaderData, UploadFileData } from "types";
import { ColumnSelect } from "./column-select/column-select";
import { EMAIL_COLUMN_NAME, createInitialArray, getFirstRow } from "./file-table.constants";

import globalStyles from "assets/styles/global.module.scss";
import styles from "./file-table.module.css";

export type FileTableProps = {
  onChange?: (value: UploadFileData) => void;
  value?: UploadFileData | null;
  parsedFile: Array<Array<string>>;
  label?: string;
  error?: string;
  disabled?: boolean;
  loading?: boolean;
  selector?: "select" | "checkbox" | "radio";
  isEmailRequired?: boolean;
  showHeadersSwitch?: boolean;
  autocompleteColumns?: boolean;
  status?: "default" | "error" | "warning" | "success";
  columnOptions?: SelectOption<string>[];
};

export const FileTable: FC<FileTableProps> = ({
  value,
  parsedFile,
  onChange,
  label = "Set column(s) data type(s)",
  error = "",
  autocompleteColumns = true,
  disabled = false,
  status = "default",
  loading = false,
  showHeadersSwitch = true,
  selector = "select",
  columnOptions,
}) => {
  const [hasHeaders, setHasHeaders] = useState(Boolean(value?.hasHeaders));

  const columnsAmount = parsedFile?.[0]?.length || 0;
  const firstRow = getFirstRow(parsedFile);

  const handleSelectHeaders = (headerValues: SelectOption<string>[], hasHeadersValue: boolean, index?: number) => {
    const newData: FileHeaderData = {
      ...value,
      emailIndex: -1,
      headers: headerValues
        .map((header) => (!header ? { value: "", label: "" } : header))
        .map((header) => header.value),
      hasHeaders: hasHeadersValue,
    };

    const emailIndex = headerValues.findIndex((header) => header?.label?.toUpperCase() === "EMAIL");

    if (emailIndex !== -1) newData.emailIndex = emailIndex;
    if (index !== undefined) newData.emailIndex = index;

    onChange?.(newData);
  };

  const setFirstRowAsHeaders = () => {
    const isEmailInFirstRow = firstRow.find(
      (columnHeader) => columnHeader.toUpperCase() === EMAIL_COLUMN_NAME.toUpperCase(),
    );

    if (isEmailInFirstRow) {
      return firstRow.map((columnHeader) => {
        if (columnHeader.toUpperCase() === EMAIL_COLUMN_NAME.toUpperCase())
          return { value: columnHeader.toLowerCase(), label: EMAIL_COLUMN_NAME };
        return { value: "", label: "" };
      });
    }

    return firstRow.map((column) => {
      return { value: column.toLowerCase(), label: column };
    });
  };

  useDidUpdate(() => {
    const isInitialRender = value?.headers?.length === 0;

    if (isInitialRender) {
      // headers have not been set yet
      const isEmailInFirstRow = firstRow.some((column) => isValidEmail(column));

      if (columnsAmount === 1) {
        setHasHeaders(!isEmailInFirstRow);
        const emailHeader = [{ value: "email", label: EMAIL_COLUMN_NAME }];
        handleSelectHeaders(emailHeader, !isEmailInFirstRow);
      }

      if (columnsAmount > 1 && isEmailInFirstRow) {
        setHasHeaders(false);
        handleSelectHeaders(createInitialArray(columnsAmount), false);
      }

      if (columnsAmount > 1 && !isEmailInFirstRow) {
        setHasHeaders(true);

        if (selector === "radio") {
          handleSelectHeaders(createInitialArray(columnsAmount), true);
        } else {
          const headerValues = setFirstRowAsHeaders();
          handleSelectHeaders(headerValues, true);
        }
      }
    }
  }, [parsedFile]);

  const handleSwitchToggle = (_: unknown, value: boolean) => {
    setHasHeaders(value);

    if (value && autocompleteColumns) {
      const headerValues = setFirstRowAsHeaders();

      handleSelectHeaders(headerValues, value);
    } else if (columnsAmount) {
      handleSelectHeaders(createInitialArray(columnsAmount), value);
    }
  };

  const fileToRender = useMemo(() => {
    if (hasHeaders && selector === "checkbox") return parsedFile.slice(1);
    return parsedFile;
  }, [parsedFile, selector, hasHeaders]);

  return (
    <div className={styles.itemModal}>
      <div className={styles.itemModalContentWrap}>
        <div className={styles.itemModalTableHeaderWrap}>
          <div className={`${styles.itemModalTableHeaderHint} ${styles.itemModalWrapTitle}`}>{label}</div>
          {Boolean(parsedFile.length) && showHeadersSwitch && (
            <div className={styles.itemModalTableHasHeaderWrap}>
              Has header
              <Switch
                value="switch"
                checked={hasHeaders}
                onChange={handleSwitchToggle}
                inputSize="small"
                disabled={disabled}
                disableError
              />
              <span>{hasHeaders ? "Yes" : "No"}</span>
            </div>
          )}
        </div>
      </div>
      <div className={`${styles.itemModalContentGridContainer} ${styles.hasItems} ${styles[status]}`}>
        <div className={`${styles.itemModalContentGrid} ${globalStyles.addScrollStyles}`}>
          <ColumnSelect
            firstRow={firstRow}
            columnsAmount={columnsAmount}
            hasHeaders={hasHeaders}
            headers={value?.headers || []}
            setHeaders={handleSelectHeaders}
            disabled={disabled}
            selector={selector}
            columnOptions={columnOptions}
          />
          {fileToRender.map((row, rowIndex) => {
            const isHeaderRow = hasHeaders && rowIndex === 0 && selector !== "checkbox";

            return (
              <div key={rowIndex} className={`${styles.itemModalGridRow} ${isHeaderRow ? styles.headerRow : ""}`}>
                {row.map((column, columnIndex) => (
                  <div className={styles.itemModalGridCell} key={columnIndex}>
                    <span className={styles.itemModalGridCellText}>{column}</span>
                  </div>
                ))}
              </div>
            );
          })}
          <div className={styles.gridScrollBGRow} />
          {!loading && !parsedFile.length && (
            <div className={styles.noContent}>
              <NoContent
                title="File is empty"
                subtitle="There is no preview after file parsing"
                size="medium"
                showButton={false}
              />
            </div>
          )}
          {loading && <div className={styles.noContent}>Loading file preview...</div>}
        </div>
      </div>
      <MessageField message={error} />
    </div>
  );
};
