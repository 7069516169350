import React, { useMemo, useState } from "react";
import { Button, Label, Select, SelectOption, TextEllipsis } from "@epcnetwork/core-ui-kit";
import { useNavigate } from "react-router-dom";
import { useFetch } from "@hyper-fetch/react";
import { List, CheckCircle } from "lucide-react";
import cn from "classnames";

import { getActiveDataExtensions, getActiveProjects } from "api";
import { BUSINESS_UNIT_DETAILS_PAGE, ITERABLE_ACCOUNTS_LIST_PAGE } from "constants/routes.constants";
import { Plus } from "assets";

import styles from "./lists-select.module.scss";

interface Props {
  selectedProjects: number[];
  setSelectedProjects: React.Dispatch<React.SetStateAction<number[]>>;
  selectedDataExtensions: number[];
  setSelectedDataExtensions: React.Dispatch<React.SetStateAction<number[]>>;
}

export const ListsSelect: React.FC<Props> = ({
  selectedProjects,
  setSelectedProjects,
  selectedDataExtensions,
  setSelectedDataExtensions,
}) => {
  const navigate = useNavigate();

  const [projectsOptions, setProjectOptions] = useState<SelectOption<number>[]>([]);
  const [dataExtensionsOptions, setDataExtensionsOptions] = useState<SelectOption<number>[]>([]);

  const { data: projects, onSuccess: onProjectsSuccess, loading: projectsLoading } = useFetch(getActiveProjects);
  onProjectsSuccess(({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    setProjectOptions(options);
    setSelectedProjects(response.map((item) => item.id));
  });

  const {
    data: dataExtensions,
    onSuccess: onDataExtensionsSuccess,
    loading: dataExtensionsLoading,
  } = useFetch(getActiveDataExtensions);
  onDataExtensionsSuccess(({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    setDataExtensionsOptions(options);
    setSelectedDataExtensions(response.map((item) => item.id));
  });

  const accounts = useMemo(() => {
    if (!projects || projects?.length === 0) return [];

    return projects.reduce<{ id: number; name: string }[]>((acc, value) => {
      if (!acc.some((item) => item.id === value.accountId)) {
        acc.push({ id: value.accountId, name: value.accountName });
      }

      return acc;
    }, []);
  }, [projects]);

  const allFromAccountSelected = (accountId: number) => {
    if (!projects || projects?.length === 0 || accounts.length === 0) return false;

    const accountProjects = projects.filter((project) => project.accountId === accountId);

    return accountProjects.every((project) => selectedProjects.includes(project.id));
  };

  const handleSelectFromAccount = (accountId: number) => () => {
    const accountProjectsIds =
      projects?.filter((project) => project.accountId === accountId)?.map((project) => project.id) || [];

    const haveAllSelected = accountProjectsIds.every((projectId) => selectedProjects.includes(projectId));

    if (haveAllSelected) {
      // filter all projects from account
      setSelectedProjects((prevState) => prevState.filter((project) => !accountProjectsIds?.includes(project)));
      return;
    }

    // filter already selected projects from an account and then select all from that account
    setSelectedProjects((prevState) => {
      const filteredProjects = prevState.filter((project) => !accountProjectsIds?.includes(project));
      return [...filteredProjects, ...accountProjectsIds];
    });
  };

  const businessUnits = useMemo(() => {
    if (!dataExtensions || dataExtensions?.length === 0) return [];

    return dataExtensions.reduce<{ id: number; name: string }[]>((acc, value) => {
      if (!acc.some((item) => item.id === value.businessUnitId)) {
        acc.push({ id: value.businessUnitId, name: value.businessUnitName });
      }

      return acc;
    }, []);
  }, [dataExtensions]);

  const allFromBusinessUnitSelected = (businessUnitId: number) => {
    if (!dataExtensions || dataExtensions?.length === 0 || accounts.length === 0) return false;

    const unitDataExtensions = dataExtensions.filter(
      (dataExtension) => dataExtension.businessUnitId === businessUnitId,
    );

    return unitDataExtensions.every((dataExtension) => selectedDataExtensions.includes(dataExtension.id));
  };

  const handleSelectFromBusinessUnit = (businessUnitId: number) => () => {
    const unitDataExtensionsIds =
      dataExtensions
        ?.filter((dataExtension) => dataExtension.businessUnitId === businessUnitId)
        ?.map((dataExtension) => dataExtension.id) || [];

    const haveAllSelected = unitDataExtensionsIds.every((dataExtension) =>
      selectedDataExtensions.includes(dataExtension),
    );

    if (haveAllSelected) {
      // filter all data extensions from business unit
      setSelectedDataExtensions((prevState) =>
        prevState.filter((dataExtension) => !unitDataExtensionsIds?.includes(dataExtension)),
      );
      return;
    }

    // filter already selected data extensions from a business unit and then select all from that particular business unit
    setSelectedDataExtensions((prevState) => {
      const filteredDataExtensions = prevState.filter((project) => !unitDataExtensionsIds?.includes(project));
      return [...filteredDataExtensions, ...unitDataExtensionsIds];
    });
  };

  const projectsEmpty = projects?.length === 0 && !projectsLoading;
  const dataExtensionsEmpty = dataExtensions?.length === 0 && !dataExtensionsLoading;

  return (
    <div className="suppress-details">
      <div className={styles.projectSelect}>
        <Label text="Projects to suppress" isInputLabel />

        {projectsEmpty && (
          <div>
            <p className={styles.projectDescription}>
              You currently have no active projects. Click the button bellow, select the account and activate or create
              a new project.
            </p>

            <Button appearance="primary" onClick={() => navigate(ITERABLE_ACCOUNTS_LIST_PAGE.path)} btnSize="small">
              <Plus />
              Create first project
            </Button>
          </div>
        )}
        {!projectsEmpty && (
          <>
            <p className={styles.projectDescription}>Select projects these suppressions will be added to</p>
            <div className={styles.grid}>
              {accounts.map((account) => {
                const selectedAll = allFromAccountSelected(account.id);

                return (
                  <button
                    key={account.id}
                    type="button"
                    className={cn(styles.button, { [styles.buttonActive]: selectedAll })}
                    onClick={handleSelectFromAccount(account.id)}
                  >
                    {selectedAll ? <CheckCircle width={14} height={14} /> : <List width={14} height={14} />}
                    <TextEllipsis>
                      {selectedAll ? "Selected" : "Select"} all from <strong>{account.name}</strong>
                    </TextEllipsis>
                  </button>
                );
              })}
            </div>
            <Select
              name="projectIds"
              options={projectsOptions}
              isSearchable
              isMulti
              selectedOptionsKeys={selectedProjects}
              onChange={(options) => setSelectedProjects(options.map((item) => item.value))}
              searchPlaceholder="Search projects"
              asyncOptions={{ loading: projectsLoading }}
              disabled={projectsLoading}
              error={
                selectedProjects.length === 0 && !projectsLoading ? "At least one Iterable project is required" : ""
              }
            />
          </>
        )}
      </div>

      <div className={styles.projectSelect}>
        <Label text="Data extensions to suppress" isInputLabel />

        {dataExtensionsEmpty && (
          <>
            <div>
              <p className={styles.projectDescription}>
                You currently have no active data extensions. Click the button bellow, select the business unit and
                activate or create a new data extension.
              </p>

              <Button appearance="primary" onClick={() => navigate(BUSINESS_UNIT_DETAILS_PAGE.path)} btnSize="small">
                <Plus />
                Create first data extension
              </Button>
            </div>
          </>
        )}

        {!dataExtensionsEmpty && (
          <>
            <p className={styles.projectDescription}>Select data extensions to which suppresses will be added</p>
            <div className={styles.grid}>
              {businessUnits.map((businessUnit) => {
                const selectedAll = allFromBusinessUnitSelected(businessUnit.id);

                return (
                  <button
                    key={businessUnit.id}
                    type="button"
                    className={cn(styles.button, { [styles.buttonActive]: selectedAll })}
                    onClick={handleSelectFromBusinessUnit(businessUnit.id)}
                  >
                    {selectedAll ? <CheckCircle width={14} height={14} /> : <List width={14} height={14} />}
                    <TextEllipsis>
                      {selectedAll ? "Selected" : "Select"} all from <strong>{businessUnit.name}</strong>
                    </TextEllipsis>
                  </button>
                );
              })}
            </div>

            <Select
              name="dataExtensionIds"
              options={dataExtensionsOptions}
              selectedOptionsKeys={selectedDataExtensions}
              onChange={(options) => setSelectedDataExtensions(options.map((item) => item.value))}
              isSearchable
              isMulti
              searchPlaceholder="Search data extensions"
              asyncOptions={{ loading: dataExtensionsLoading }}
              disabled={dataExtensionsLoading}
            />
          </>
        )}
      </div>
    </div>
  );
};
