import React from "react";
import { Link } from "react-router-dom";
import { ChevronRight } from "lucide-react";

import styles from "./breadcrumbs.module.scss";

export type BreadcrumbsOption = { name: string; path: string } | string;

export type BreadcrumbsProps = {
  options: BreadcrumbsOption[];
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ options }) => {
  return (
    <div className={styles.container}>
      {options.map((option, index) => {
        const isLastIndex = index === options.length - 1;

        return (
          <>
            {typeof option === "string" ? (
              <span className={styles.item}>{option}</span>
            ) : (
              <Link to={option.path} className={styles.link}>
                <span>{option.name}</span>
              </Link>
            )}
            {!isLastIndex && <ChevronRight width={14} height={14} />}
          </>
        );
      })}
    </div>
  );
};
