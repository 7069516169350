import React, { useMemo, useState } from "react";
import { useFetch, useSubmit } from "@hyper-fetch/react";
import { useParams } from "react-router-dom";
import { Button, notification, Search, Switch, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";
import cn from "classnames";

import {
  BreadcrumbsOption,
  Container,
  DeleteMultipleButton,
  TableError,
  TableNoContent,
  SelectAllButton,
} from "components";
import { useItemManagement, usePermissions, useSidebar, useTablePagination, useTour } from "hooks";
import { IterableProjectModel } from "models";
import { Plus } from "assets";
import { deleteProject, getAccount, getProjects, GetProjectsQuery, updateAccount } from "api";
import { iterableProjectsColumns, initialFilters, TABLE_NAME } from "./iterable-projects.constants";
import { IterableProjectItem } from "./project-item/iterable-project-item";
import { UpdateIterableProject, CreateIterableProject } from "pages/integrations/iterable";
import { addItem, getInitialStorageFilters, removeItem, updateItem } from "utils";
import { DeleteResponse } from "api/api.types";
import { Navbar } from "components/layout/navbar/navbar";
import { ITERABLE_ACCOUNTS_LIST_PAGE } from "constants/routes.constants";
import { steps } from "./iterable-projects.tour";

import styles from "./iterable-projects.module.scss";

export const IterableProjectsPage: React.FC = () => {
  useTour({
    name: "iterable-details",
    steps,
  });

  const { isMenuCollapsed, setMenuCollapsed } = useSidebar();
  const { accountId } = useParams<{ accountId: string }>();
  const { create, update } = useItemManagement<IterableProjectModel>();
  const { query, searchValue, setSearch } = useFilters<GetProjectsQuery>(
    getInitialStorageFilters<GetProjectsQuery>(TABLE_NAME, initialFilters),
  );

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [canCreateProject, canDeleteProject] = usePermissions(["create", "delete"], "projects");

  const accountDetails = useFetch(getAccount.setParams({ accountId: accountId || "" }), {
    disabled: !accountId,
  });
  const projectsDetails = useFetch(getProjects.setQueryParams({ ...query, accountId: accountId || "" }), {
    disabled: !accountId,
  });
  const pagination = usePagination({ listPayload: projectsDetails.data });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  const edit = useSubmit(updateAccount);
  edit.onSubmitSuccess(({ response }) => {
    accountDetails.setData(response);
    notification.success(
      response.isActive ? "Iterable account activated" : "Iterable account deactivated",
      response.isActive ? "Iterable account is now active." : "Iterable account is deactivated.",
    );
  });
  edit.onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the account.");
  });

  const { submit, onSubmitSuccess, onSubmitError } = useSubmit(deleteProject);
  onSubmitSuccess(() => {
    notification.success("Iterable projects deleted", "Iterable projects successfully deleted");
    projectsDetails.refetch();
    setSelectedRows([]);
  });
  onSubmitError(() => {
    notification.error("Delete error", "Cannot delete these Iterable projects.");
  });

  const handleEdit = (project: IterableProjectModel) => {
    update.setItem(project);
  };

  const handleCreateSuccess = (projects: IterableProjectModel[]) => {
    if (projectsDetails.data) addItem(projects, projectsDetails.data, projectsDetails.setData);
  };

  const handleUpdateSuccess = (project: IterableProjectModel) => {
    if (projectsDetails.data) updateItem(project, projectsDetails.data, projectsDetails.setData);
  };

  const handleDeleteSuccess = (response: DeleteResponse[]) => {
    if (projectsDetails.data) removeItem(response[0], projectsDetails.data, projectsDetails.setData);
  };

  const handleItemSelect = (id: number, checked: boolean) => {
    const rows = checked ? [id, ...selectedRows] : selectedRows.filter((row) => row !== id);
    setSelectedRows(rows);
  };

  const allSelected = useMemo(() => {
    if (!projectsDetails.data) return false;

    return selectedRows.length === projectsDetails.data.data.length;
  }, [selectedRows, projectsDetails]);

  const handleSelectAll = () => {
    if (projectsDetails.data) {
      const ids = projectsDetails.data.data.map((project) => project.id);
      setSelectedRows(selectedRows.length === ids.length ? [] : ids);
    }
  };

  const handleMultipleDelete = () => {
    notification.confirm(
      `Do you want to delete ${selectedRows.length} Iterable project${selectedRows.length > 1 ? "s" : ""}?`,
      "This action is irreversible.",
      {
        onOk: async () => {
          await submit({ data: { ids: selectedRows } });
        },
      },
    );
  };

  const toggleSidebar = () => setMenuCollapsed(!isMenuCollapsed);

  const handleSwitchChange = async (value: string, checked: boolean) => {
    if (accountDetails.data)
      await edit.submit({ data: { isActive: checked }, params: { accountId: accountDetails.data.id } });
  };

  useDidUpdate(() => {
    setSelectedRows([]);
  }, [pagination.currentOffset]);

  const breadcrumbs: BreadcrumbsOption[] = [
    { name: "Iterable accounts", path: ITERABLE_ACCOUNTS_LIST_PAGE.path },
    accountDetails?.data?.name || "",
  ];

  return (
    <>
      <Navbar
        isMenuCollapsed={isMenuCollapsed}
        toggleSidebar={toggleSidebar}
        name={`${accountDetails.data?.name || ""} projects`}
        loading={accountDetails.loading}
        backPage={ITERABLE_ACCOUNTS_LIST_PAGE.path}
        navbarClassName="iterable-details-navbar"
        breadcrumbs={breadcrumbs}
      >
        <div className={cn(styles.row, "active-switch")}>
          <div className={styles.switch}>
            <Switch
              value="toggle"
              checked={accountDetails.data?.isActive || false}
              disableError
              onChange={handleSwitchChange}
            />
          </div>
          {accountDetails.data?.isActive && (
            <div className={styles.notActiveText}>
              Account is currently active. All active projects will be included for suppressions.
              <br />
              You can deactivate this account using the toggle switch on the left.
            </div>
          )}
          {!accountDetails.data?.isActive && (
            <div className={styles.notActiveText}>
              This account is not currently active. None of these projects will be included for suppressions.
              <br />
              You can activate this account using the toggle switch on the left.
            </div>
          )}
        </div>
      </Navbar>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Search className={styles.search} searchValue={searchValue} setSearch={setSearch} />
            <div className={styles.row}>
              {canDeleteProject && selectedRows.length > 0 && (
                <DeleteMultipleButton
                  onClick={handleMultipleDelete}
                  text={`Delete selected projects (${selectedRows.length})`}
                  total={selectedRows.length}
                />
              )}

              <SelectAllButton onClick={handleSelectAll} allSelected={allSelected} />

              {canCreateProject && (
                <Button onClick={create.open} className="add-project" btnSize="small">
                  <Plus /> Add new project
                </Button>
              )}
            </div>
          </div>
          <Table
            entityName={TABLE_NAME}
            columns={iterableProjectsColumns}
            list={projectsDetails?.data?.data}
            error={projectsDetails.error?.message}
            refresh={projectsDetails.refetch}
            loading={projectsDetails.loading}
            resetColumnsOnMount={false}
            contentClassName={styles.tableHeader}
            customNoContent={<TableNoContent withBackground />}
            customError={<TableError description={projectsDetails.error?.message} withBackground />}
            pagination={{
              ...pagination,
              elementsPerPage: currentElementsPerPage,
              onElementsPerPageChange: handlePerPageChange,
            }}
            row={(project, index) => (
              <IterableProjectItem
                index={index}
                project={project}
                handleEdit={handleEdit}
                account={accountDetails?.data}
                onRemoveSuccess={handleDeleteSuccess}
                isSelected={selectedRows.includes(project.id)}
                onSelect={handleItemSelect}
                onUpdateSuccess={handleUpdateSuccess}
              />
            )}
          />
          <UpdateIterableProject
            project={update.item}
            isOpen={update.isOpen}
            close={update.close}
            onUpdateSuccess={handleUpdateSuccess}
          />
        </div>

        {accountDetails?.data && (
          <CreateIterableProject
            isOpen={create.isOpen}
            close={create.close}
            refresh={projectsDetails.refetch}
            account={accountDetails.data}
            onCreateSuccess={handleCreateSuccess}
          />
        )}
      </Container>
    </>
  );
};
