import { FC, useRef } from "react";
import {
  TableRow,
  TableCell,
  Menu,
  MenuButton,
  notification,
  TextEllipsis,
  getLinkPath,
  Switch,
  useQuery,
  Checkbox,
  Nullable,
} from "@epcnetwork/core-ui-kit";
import { useSubmit } from "@hyper-fetch/react";
import { useNavigate, useParams } from "react-router-dom";

import { usePermissions } from "hooks";
import { deleteClients, updateClient } from "api";
import { OPTIZMO_OFFERS_LIST_PAGE } from "constants/routes.constants";
import { DeleteResponse } from "api/api.types";
import { Badge } from "components";
import { ClientModel, CompanyModel } from "models";

import styles from "./optizmo-client-item.module.scss";

type OptizmoClientItemProps = {
  index: number;
  client: ClientModel;
  handleEdit: (client: ClientModel) => void;
  onRemoveSuccess: (response: DeleteResponse) => void;
  onActivateSuccess: (client: ClientModel) => void;
  onSelect: (id: number, checked: boolean) => void;
  isSelected: boolean;
  company: Nullable<CompanyModel>;
};

export const OptizmoClientItem: FC<OptizmoClientItemProps> = ({
  index,
  client,
  handleEdit,
  onRemoveSuccess,
  onActivateSuccess,
  onSelect,
  isSelected,
  company,
}) => {
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const switchRef = useRef<HTMLDivElement | null>(null);

  const { query, stringify } = useQuery();
  const { companyId } = useParams<{ companyId: string }>();

  const [canUpdateAccount, canDeleteAccount] = usePermissions(["update", "delete"], "accounts");

  const remove = useSubmit(deleteClients);
  remove.onSubmitSuccess(({ response }) => {
    onRemoveSuccess(response[0]);
    notification.success("Optizmo company deleted", "Optizmo company successfully deleted");
  });
  remove.onSubmitError(() => {
    notification.error("Delete error", "Cannot delete this Iterable account.");
  });

  const update = useSubmit(updateClient);
  update.onSubmitSuccess(({ response }) => {
    onActivateSuccess(response);
    notification.success(
      response.isActive ? "Optizmo client activated" : "Optizmo client deactivated",
      response.isActive ? "Optizmo client is now active." : "Optizmo client is deactivated.",
    );
  });
  update.onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the company");
  });

  const handleRemoveItem = () => {
    notification.confirm(
      "Do you want to delete this Optizmo client?",
      "This action is irreversible and will remove all Optizmo offers assigned to this client!",
      {
        onOk: async () => {
          await remove.submit({ data: { ids: [client.id] } });
        },
      },
    );
  };

  const handleEditItem = () => handleEdit(client);

  const handleCheckboxClick = (value: string, isChecked: boolean) => {
    onSelect(client.id, isChecked);
  };

  const handleRowClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const excludeElements = [menuRef.current, switchRef.current];

    const containsExcluded = excludeElements.some((element) => element?.contains(e.target as HTMLElement));
    const isExcluded = excludeElements.some((element) => element === e.target);
    if (!containsExcluded && !isExcluded && companyId) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const path = getLinkPath(OPTIZMO_OFFERS_LIST_PAGE.path, { companyId, clientId: client.id });
      // set navigate state to allow going back to with the same query params
      navigate(path, { state: { queryStringified: stringify(query) } });
    }
  };

  const handleSwitchChange = async (value: string, checked: boolean) => {
    await update.submit({ data: { isActive: checked }, params: { clientId: client.id } });
  };

  return (
    <TableRow id={client.id} onRowClick={handleRowClick} className={styles.row}>
      <TableCell className={styles.firstCell}>
        <div className={styles.checkboxCell}>
          <Checkbox
            value={client.id}
            onChange={handleCheckboxClick}
            onClick={(e) => e.stopPropagation()}
            className={styles.checkbox}
            checked={isSelected}
            name={`client-${client.id}`}
            inputSize="big"
            disableError
            showBox
          />
        </div>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {client.name}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <Badge>{client.companyId}</Badge>
      </TableCell>

      <TableCell>
        <div className={styles.switch} ref={switchRef}>
          <Switch
            value="toggle"
            checked={client.isActive}
            disableError
            onChange={handleSwitchChange}
            disabled={!company?.isActive}
          />
        </div>
      </TableCell>

      <TableCell>
        <div ref={menuRef} data-testid={`iterable-menu-button-${index}`}>
          <Menu portalClassName={`iterable-menu-${index}`}>
            {canUpdateAccount && (
              <MenuButton onClick={handleEditItem} icon="edit" data-testid={`iterable-edit-${index}`}>
                Edit
              </MenuButton>
            )}
            {canDeleteAccount && (
              <MenuButton
                appearance="delete"
                onClick={handleRemoveItem}
                icon="delete"
                data-testid={`iterable-delete-${index}`}
              >
                Delete
              </MenuButton>
            )}
            {!canUpdateAccount && !canDeleteAccount && <div style={{ padding: "10px" }}>No Actions available</div>}
          </Menu>
        </div>
      </TableCell>
    </TableRow>
  );
};
