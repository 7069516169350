import { client } from "api/client.api";
import { TokensResponse } from "api/auth";
import { ServerErrorType } from "api/api.types";
import { MultiFactorAuthData } from "api/mfa/mfa.types";

export const generateMfaUrl = client.createRequest<string>()({
  method: "GET",
  endpoint: "/auth/generate-otp-url",
  options: { timeout: 0 },
});

export const enableMfa = client.createRequest<TokensResponse, MultiFactorAuthData>()({
  method: "POST",
  endpoint: "/auth/otp-enable",
  options: { timeout: 0 },
});

export const authenticateMfa = client.createRequest<TokensResponse, MultiFactorAuthData>()({
  endpoint: "/auth/otp-auth",
  method: "POST",
  auth: false,
  options: { timeout: 0 },
});
