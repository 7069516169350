import { useNavigate } from "react-router-dom";
import { FC } from "react";
import cn from "classnames";
import { Sidebar as SidebarComponent } from "@epcnetwork/core-ui-kit";
import { useDispatch } from "react-redux";

import { LOGOUT_PAGE, SETTINGS_PAGE } from "constants/routes.constants";
import { SIDEBAR_CONSTANTS } from "./sidebar.constants";
import { Menu } from "./menu/menu";
import { Logo } from "./logo/logo";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";
import { setChangelogOpen } from "store";
import pkg from "../../../../package.json";

import { ArrowRightGrey, Exit, QuestionMarkCircle, Settings, XRoundedGrey } from "assets/icons";

import styles from "./sidebar.module.scss";

type SidebarProps = {
  collapsed: boolean;
  isMobile: boolean;
  handleSidebarCollapsed: (value: boolean) => void;
  toggleSidebar: () => void;
};

export const Sidebar: FC<SidebarProps> = ({ collapsed, isMobile, handleSidebarCollapsed, toggleSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => navigate(LOGOUT_PAGE.path);
  const handleSettings = () => navigate(SETTINGS_PAGE.path);
  const handleChangelogOpen = () => dispatch(setChangelogOpen(true));

  const closeSidebarMobile = () => {
    if (isMobile) {
      handleSidebarCollapsed(true);
    }
  };

  const backDropClassName = cn(styles.backdrop, { [styles.collapsed]: collapsed });
  const sidebarClassName = cn(styles.sidebar, styles.override, { [styles.collapsed]: collapsed });
  const toggleClassName = cn(styles.toggler, styles[SIDEBAR_CONSTANTS.placement], { [styles.collapsed]: collapsed });

  return (
    <>
      <div className={backDropClassName} onClick={closeSidebarMobile} />
      <SidebarComponent
        wrapperClassName={cn(styles.sidebarWrapper, styles.override, {
          [styles.collapsed]: collapsed,
        })}
        contentClassName={sidebarClassName}
        setIsCollapsed={handleSidebarCollapsed}
        isCollapsed={collapsed}
        sidebarWidth={SIDEBAR_CONSTANTS.width}
        collapsedWidth={isMobile ? SIDEBAR_CONSTANTS.width : SIDEBAR_CONSTANTS.collapsedDesktopWidth}
        entityName={STORAGE_FIELDS.sidebarStatus}
        isContentOffset={true}
      >
        <Logo />

        {isMobile ? (
          <button onClick={closeSidebarMobile} className={styles.closeSidebarBtn}>
            <XRoundedGrey className={styles.closeIcon} />
          </button>
        ) : (
          <button className={toggleClassName} onClick={toggleSidebar}>
            <ArrowRightGrey className={styles.togglerImage} />
          </button>
        )}
        <Menu closeSidebarMobile={closeSidebarMobile} />

        <div className={styles.bottomContent}>
          {/*<div>*/}
          {/*  <button*/}
          {/*    className={cn(styles.changelogButton, { [styles.sidebarItemWideGap]: collapsed })}*/}
          {/*    onClick={handleChangelogOpen}*/}
          {/*  >*/}
          {/*    <QuestionMarkCircle />*/}
          {/*    <div>*/}
          {/*      What&apos;s new?*/}
          {/*      <span>Version: {pkg.version}</span>*/}
          {/*    </div>*/}
          {/*  </button>*/}
          {/*</div>*/}

          <div>
            <button
              className={cn(styles.sidebarItem, { [styles.sidebarItemWideGap]: collapsed })}
              onClick={handleSettings}
            >
              <Settings />
              <div>Settings</div>
            </button>
            <button
              className={cn(styles.sidebarItem, { [styles.sidebarItemWideGap]: collapsed })}
              onClick={handleLogout}
            >
              <Exit />
              <div>Sign out</div>
            </button>
          </div>
        </div>
      </SidebarComponent>
    </>
  );
};
