import { client } from "api/client.api";
import { List, WhitelistItemModel } from "models";
import { CreateWhitelistItemData, GetWhitelistItemsQuery } from "./whitelist.types";
import { DeleteResponse, ServerErrorType } from "api/api.types";

export const createWhitelistItem = client.createRequest<WhitelistItemModel, CreateWhitelistItemData>()({
  method: "POST",
  endpoint: "/users/white-list",
  options: { timeout: 0 },
});

export const getWhitelistItems = client.createRequest<
  List<WhitelistItemModel>,
  null,
  ServerErrorType,
  GetWhitelistItemsQuery
>()({
  method: "GET",
  endpoint: "/users/white-list",
  options: { timeout: 0 },
});

export const deleteWhitelistItem = client.createRequest<DeleteResponse>()({
  method: "DELETE",
  endpoint: `/users/white-list/:recordId`,
});
