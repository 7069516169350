import { client } from "api/client.api";
import { List, UserModel } from "models";
import { GetUsersQuery, UpdateUserData } from "./users.types";
import { ServerErrorType } from "api/api.types";

export const getUsers = client.createRequest<List<UserModel>, null, ServerErrorType, GetUsersQuery>()({
  method: "GET",
  endpoint: "/users",
  options: { timeout: 0 },
});

export const getUser = client.createRequest<UserModel>()({
  method: "GET",
  endpoint: "/users/:userId",
  options: { timeout: 0 },
});

export const getMe = client.createRequest<UserModel>()({
  method: "GET",
  endpoint: "/users/me",
  options: { timeout: 0 },
});

export const updateUser = client.createRequest<UserModel, UpdateUserData>()({
  method: "PUT",
  endpoint: `/users/:userId`,
});
