import React from "react";
import { Nullable, SelectOption, UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { ColumnsType } from "types";
import { DEFAULT_OFFSET } from "constants/query.constants";
import { AuditLogsQueryParams } from "api";
import { AuditAction, AuditType } from "models";
import { Enter, MinusCircle, Pencil, PlusCircle } from "assets";
import { BadgeProps } from "components";

import styles from "./audit-logs-list.module.scss";

export const auditLogsColumns: ColumnsType = [
  { label: "User", required: true, queryKey: "user" },
  { label: "Date", required: true, queryKey: "date" },
  { label: "Action", required: true },
  { label: "Name", queryKey: "name" },
  { label: "" },
];

export const TABLE_NAME = "audit-logs-table";

export const initialFilters: UseFiltersProps<AuditLogsQueryParams> = {
  initialState: {
    search: "",
    limit: "50",
    offset: DEFAULT_OFFSET,
  },
};

export const actionOptions: SelectOption<AuditAction | "">[] = [
  { label: "All", value: "" },
  { label: "Create", value: "create", icon: <PlusCircle className={styles.icon} /> },
  { label: "Update", value: "update", icon: <Pencil className={styles.icon} /> },
  { label: "Delete", value: "delete", icon: <MinusCircle className={styles.icon} /> },
  { label: "Login", value: "login", icon: <Enter className={styles.icon} /> },
];

export const typeOptions: SelectOption<AuditType | "">[] = [
  { label: "All", value: "" },
  { label: "Account", value: "account" },
  { label: "Project", value: "project" },
  { label: "Business unit", value: "businessUnit" },
  { label: "Data extension", value: "dataExtension" },
  { label: "Suppression", value: "suppressionJob" },
  { label: "User", value: "user" },
  { label: "Whitelist", value: "whiteListRecord" },
  { label: "Company", value: "company" },
  { label: "Client", value: "client" },
  { label: "Offer", value: "offer" },
];

export const actions: Record<AuditAction, { variant: BadgeProps["variant"]; icon: React.ReactNode }> = {
  create: { icon: <PlusCircle />, variant: "success" },
  update: { icon: <Pencil />, variant: "info" },
  delete: { icon: <MinusCircle />, variant: "error" },
  login: { icon: <Enter />, variant: "default" },
};

export const renderTypeLabel = (type?: Nullable<string>, name?: Nullable<string>) => {
  if (!type) return "";
  if (type) {
    const label = typeOptions.find((option) => option.value === type);
    return ` ${label?.label.toLowerCase() || type} ${name ? ` - ${name}` : ""}`;
  }
};
