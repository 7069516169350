import { FC, useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { Button, Modal, MessageField, FormButtons, useFileParser } from "@epcnetwork/core-ui-kit";

import { FormField as LocalFormField } from "components";
import { IntegrationFileValues, IntegrationMode } from "./integrations-values-modal.types";
import { IntegrationFileItem } from "types";
import { integrationColumns, integrationSchema } from "./integrations-values-modal.constants";

import styles from "./integrations-values-modal.module.scss";

type IntegrationsValuesModalProps = {
  file: IntegrationFileItem;
  onCloseClick: VoidFunction;
  onSubmitClick: (fileValues: IntegrationFileItem) => void;
  integrationMode: IntegrationMode;
};

export const IntegrationsValuesModal: FC<IntegrationsValuesModalProps> = ({
  file,
  onCloseClick,
  onSubmitClick,
  integrationMode,
}) => {
  const { loading, parsedFile, error } = useFileParser(file.originalFile);

  const [initialValues] = useState<IntegrationFileValues>({
    headerValues: {
      hasHeaders: file.data.hasHeaders,
      headers: file.data.headers,
    },
  });

  const onSubmit = (values: IntegrationFileValues, { setSubmitting }: FormikHelpers<IntegrationFileValues>) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: {
        ...values,
        ...file.data,
        ...values.headerValues,
      },
    });
    setSubmitting(false);
  };

  return (
    <Modal
      isOpen={Boolean(file)}
      setClose={onCloseClick}
      contentClassName={`${styles.modal} integrations-modal-content`}
    >
      <h2 className={styles.title}>Set values {file.originalFile.name}</h2>
      <MessageField message={error} />
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        loading={loading}
        validationSchema={integrationSchema[integrationMode]}
      >
        <Form>
          <LocalFormField
            type="file-table"
            name="headerValues"
            label="Select Email column"
            parsedFile={parsedFile}
            loading={loading}
            selector="select"
            autocompleteColumns={false}
            columnOptions={integrationColumns[integrationMode]}
          />
          <FormButtons>
            <Button appearance="secondary" onClick={onCloseClick}>
              Cancel
            </Button>
            <Button type="submit">Set values</Button>
          </FormButtons>
        </Form>
      </Formik>
    </Modal>
  );
};
