import React, { FC } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form } from "formik";
import { Button, MessageField, FormButtons, notification, Modal, Input, Label } from "@epcnetwork/core-ui-kit";
import { useSelector } from "react-redux";

import { updateUser } from "api";
import { TabOption, Tabs } from "components";
import { UpdateUserFormData, UpdateUserProps } from "./update-user.types";
import { validationSchema, initialValues } from "./update-user.constants";
import { RootState } from "store";

import styles from "./update-user.module.scss";

export const UpdateUser: FC<UpdateUserProps> = ({ onUpdateSuccess, user, isOpen, close }) => {
  const { user: currentUser } = useSelector((state: RootState) => state.auth);

  const { submit, error, onSubmitSuccess, onSubmitError } = useSubmit(updateUser);
  onSubmitSuccess(({ response }) => {
    onUpdateSuccess(response);
    close();
    notification.success("User updated!", "User has been successfully updated.");
  });
  onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the user.");
  });

  const handleSubmit = async (values: UpdateUserFormData) => {
    if (user) {
      await submit({ data: values, params: { userId: user.id } });
    }
  };

  const roleOptions: TabOption<boolean>[] = [
    { value: true, label: "Admin" },
    { value: false, label: "User" },
  ];

  return (
    <Modal isOpen={isOpen} setClose={close}>
      <Formik initialValues={initialValues(user)} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {({ values, setFieldValue }) => {
          const handleRangeChange = async (option: TabOption<boolean>) => {
            await setFieldValue("isAdmin", option.value);
          };

          return (
            <Form>
              <div className={styles.title}>User Form</div>
              <MessageField message={error?.message || ""} />
              <div className={styles.row}>
                <Input
                  type="text"
                  name="email"
                  label="Email"
                  placeholder="name@email.com"
                  required
                  autoComplete="off"
                  disabled
                  value={user?.email}
                />
                <div className={styles.tabsWrapper}>
                  <Label text="Role" isInputLabel />
                  <Tabs
                    options={roleOptions}
                    selected={values.isAdmin}
                    onChange={handleRangeChange}
                    className={styles.tabs}
                    disabled={currentUser?.id === user?.id}
                  />
                </div>
              </div>
              <FormButtons>
                <Button appearance="secondary" onClick={close}>
                  Cancel
                </Button>
                <Button type="submit">Submit</Button>
              </FormButtons>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
