import { object, string, Schema } from "yup";

import { CompanyData } from "api";

export const validationSchema: Schema<CompanyData> = object().shape({
  name: string().required("Name is required"),
  optizmoToken: string(),
});

export const initialValues: CompanyData = {
  name: "",
  optizmoToken: "",
};
