import { UserModel } from "models";
import { client } from "../client.api";
import { DevLoginData, LoginData, RefreshTokenData, TokensResponse } from "./auth.types";

export const login = client.createRequest<TokensResponse & UserModel, LoginData>()({
  endpoint: "/auth/login",
  method: "POST",
  options: { timeout: 0 },
});

export const devLogin = client.createRequest<TokensResponse & UserModel, DevLoginData>()({
  endpoint: "/auth/dev-login",
  method: "POST",
  options: { timeout: 0 },
});

export const renewToken = client.createRequest<TokensResponse, RefreshTokenData>()({
  endpoint: "/auth/refresh-token",
  method: "POST",
  auth: false,
  options: { timeout: 0 },
});

export const revokeToken = client.createRequest<null, RefreshTokenData>()({
  method: "POST",
  endpoint: "/auth/revoke-token",
  options: { timeout: 0 },
});
