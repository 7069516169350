import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Create suppression page</h2>
    <p>
      The Create Suppression page allows users to manually add emails or upload a file to suppress contacts across all
      active projects in <strong>Iterable</strong> and all active data extensions in <strong>Salesforce</strong>.
    </p>
    <p>
      Whether you need to suppress a few emails or an entire list, this page provides flexible options to ensure your
      suppression tasks are completed efficiently.
    </p>
  </div>
);

const secondStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Manual suppression</h2>
    <p>
      The textarea field enables users to input up to <strong>5 000</strong> emails, each separated by a new line. This
      is ideal for adding smaller batches of emails manually. Simply paste or type the emails into the field, ensuring
      each email is on a separate line for proper formatting.
    </p>
  </div>
);

const thirdStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Project/Data extension selection</h2>
    <p>
      Below the email input, you’ll find a multiselect field where users can choose the specific projects and data
      extensions to target for suppression.
    </p>

    <p>
      By default, all active projects and data extensions are selected, but users can customize this selection to focus
      on specific segments if needed.
    </p>
  </div>
);

const fourthStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>File upload tab</h2>
    <p>
      If you prefer to upload a file rather than manually enter emails, the File Tab allows you to switch modes.
      Clicking on this tab will change the input method, enabling you to upload a CSV file with the emails to be
      suppressed, offering a quicker way to handle large lists.
    </p>
  </div>
);

export const steps = ({ fileModeClick }: { fileModeClick: () => void }): TourStepOption[] => [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".create-suppression-navbar", on: "bottom-start" },
    permission: ["create", "suppressions"],
  },
  {
    text: renderToString(secondStep),
    attachTo: { element: ".emails-textarea", on: "top" },
    permission: ["create", "suppressions"],
  },
  {
    text: renderToString(thirdStep),
    attachTo: { element: ".suppress-details", on: "top" },
    permission: ["create", "suppressions"],
  },
  {
    text: renderToString(fourthStep),
    attachTo: { element: ".file-tab", on: "top" },
    permission: ["create", "suppressions"],
    onNextClick: fileModeClick,
  },
];
