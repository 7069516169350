import React, { useContext } from "react";
import { useFormikContext } from "formik";
import { Button, Label } from "@epcnetwork/core-ui-kit";

import { TabOption, Tabs } from "components";
import { IterableSelector } from "./iterable/iterable-selector";
import { SalesforceSelector } from "./salesforce/salesforce-selector";
import { OptizmoExportData } from "pages/md5/export/md5-export.types";
import { initialIterableData, initialSalesforceData } from "pages/md5/export/md5-export.constants";
import { Iterable, Salesforce } from "assets";
import { OptizmoExportContext } from "../../export/md5-export.context";

import styles from "pages/md5/export/md5-export.module.scss";

export const EspSelector: React.FC = () => {
  const { dataExtensionsError } = useContext(OptizmoExportContext);
  const { values, setFieldValue, isValid, isSubmitting } = useFormikContext<OptizmoExportData>();

  const handleTabChange = async ({ value }: TabOption<"iterable" | "salesforce">) => {
    await setFieldValue("esp", value);
    if (value === "iterable") await setFieldValue("espData", initialIterableData);
    if (value === "salesforce") await setFieldValue("espData", initialSalesforceData);
  };

  const options: TabOption<"iterable" | "salesforce">[] = [
    { label: "Iterable", value: "iterable", icon: <Iterable /> },
    { label: "Salesforce", value: "salesforce", icon: <Salesforce /> },
  ];

  return (
    <div className={`${styles.container} esp-selector`}>
      <div className={styles.row}>
        <div className={styles.step}>4</div>
        <div>
          <Label text="Select an ESP" isInputLabel />
        </div>
      </div>

      <Tabs selected={values.esp} onChange={handleTabChange} options={options} />

      {values.esp === "iterable" && <IterableSelector />}
      {values.esp === "salesforce" && <SalesforceSelector />}

      <div className={styles.buttons}>
        <Button type="submit" disabled={!isValid || isSubmitting || dataExtensionsError} loading={isSubmitting}>
          Submit
        </Button>
      </div>
    </div>
  );
};
