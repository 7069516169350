import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Iterable Accounts page</h2>
    <p>
      The Iterable Accounts page allows admins to list, view the number of projects linked to each account, and manage
      all Iterable accounts within the system.
    </p>
    <p>
      This page provides a centralized view of all active and inactive accounts, ensuring admins can easily monitor and
      control how these accounts interact with the suppression processes.
    </p>
  </div>
);

const secondStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Create Iterable account button</h2>
    <p>The Create button lets admins quickly add a new Iterable account to the system.</p>
    <p>
      By clicking this button, you can set up new accounts and start linking projects to them for suppression tasks.
      It&apos;s the first step in managing Iterable data within the application.
    </p>
  </div>
);

const thirdStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Accounts list</h2>
    <p>
      This table displays all Iterable accounts, providing essential information such as account name, owner, proxy and
      project counter.
    </p>
    <p>
      Each row includes an active switch, which enables or disables the entire account, including all its associated
      projects. If an account is marked as inactive, it will be excluded from any suppression tasks, ensuring precise
      control over which accounts are processed.
    </p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".iterable-navbar", on: "bottom-start" },
    permission: ["read", "integrations"],
  },
  {
    text: renderToString(secondStep),
    attachTo: { element: ".add-iterable", on: "top" },
    permission: ["create", "integrations"],
  },
  {
    text: renderToString(thirdStep),
    attachTo: { element: ".table", on: "top" },
    permission: ["read", "integrations"],
  },
];
