import { FC } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form } from "formik";
import { Button, MessageField, FormButtons, FormField, notification, Modal, Input } from "@epcnetwork/core-ui-kit";

import { updateDataExtension, UpdateDataExtensionData } from "api";
import { UpdateDataExtensionProps } from "./update-salesforce-data-extension.types";
import { validationSchema, initialValues } from "./update-salesforce-data-extension.constants";

import styles from "../update-salesforce.module.scss";

export const UpdateSalesforceDataExtension: FC<UpdateDataExtensionProps> = ({
  onUpdateSuccess,
  dataExtension,
  isOpen,
  close,
}) => {
  const { submit, error, onSubmitSuccess, onSubmitError } = useSubmit(updateDataExtension);
  onSubmitSuccess(({ response }) => {
    onUpdateSuccess(response);
    close();
    notification.success("Data Extension updated!", "Data Extension has been successfully updated.");
  });
  onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the Data Extension.");
  });

  const handleSubmit = async (values: UpdateDataExtensionData) => {
    if (dataExtension) {
      await submit({ data: values, params: { dataExtensionId: dataExtension.id } });
    }
  };

  return (
    <Modal isOpen={isOpen} setClose={close}>
      <Formik initialValues={initialValues(dataExtension)} onSubmit={handleSubmit} validationSchema={validationSchema}>
        <Form>
          <div className={styles.title}>User Form</div>
          <MessageField message={error?.message || ""} />
          <div className={styles.row}>
            <FormField type="text" name="name" label="Project name" disableFloatingLabel required />
            <Input
              type="text"
              name="externalId"
              label="External ID"
              value={dataExtension?.externalId || ""}
              disableFloatingLabel
              disabled
            />
          </div>
          <FormButtons>
            <Button appearance="secondary" onClick={close}>
              Cancel
            </Button>
            <Button type="submit">Submit</Button>
          </FormButtons>
        </Form>
      </Formik>
    </Modal>
  );
};
