import React from "react";
import cn from "classnames";

import styles from "./badge.module.scss";

export type BadgeProps = {
  children: React.ReactNode;
  className?: string;
  variant?: "default" | "success" | "finished" | "info" | "error";
};

export const Badge: React.FC<BadgeProps> = ({ className, children, variant = "default" }) => {
  return (
    <div
      className={cn(styles.badge, className, {
        [styles.pending]: variant === "default",
        [styles.finished]: variant === "finished",
        [styles.success]: variant === "success",
        [styles.failed]: variant === "error",
        [styles.info]: variant === "info",
      })}
    >
      {children}
    </div>
  );
};
