import { io, Socket } from "socket.io-client";
import { useSelector } from "react-redux";
import { useState } from "react";
import { notification, useDidMount } from "@epcnetwork/core-ui-kit";

import { RootState } from "store";
import { appEnvironment } from "config/environment.config";
import {
  Md5JobsListenerHandlers,
  Md5JobsListenerEventsKeys,
  Md5JobsSocketInstance,
} from "hooks/use-md5-jobs-socket/use-md5-jobs-socket.types";

const SOCKET_MD5_JOBS_NAMESPACE = "/md5s";

export const useMd5JobsSocket = () => {
  const [socket, setSocket] = useState<Md5JobsSocketInstance | null>(null);
  const [isConnected, setConnected] = useState<boolean>(false);

  const { token } = useSelector((state: RootState) => state.auth);

  const listenerHandlers: Partial<Md5JobsListenerHandlers> = {
    connect: () => {
      setConnected(true);
    },
    connectError: () => {
      setConnected(false);
    },
    disconnect: () => {
      setConnected(false);
    },
    exception: (error: Error) => {
      notification.error("Socket exception", "Error while connecting to the socket. " + error);
    },
  };

  const addJobListener =
    (socketInstance: Socket<Partial<Md5JobsListenerHandlers>>) =>
    <E extends Md5JobsListenerEventsKeys>(event: E) => {
      socketInstance.on<Md5JobsListenerEventsKeys>(event, listenerHandlers[event]);

      return () => {
        socketInstance.off<Md5JobsListenerEventsKeys>(event, listenerHandlers[event]);
      };
    };

  const mountListeners = (socketInstance: Socket<Md5JobsListenerHandlers>) => {
    const connectUnmount = addJobListener(socketInstance)("connect");
    const connectErrorUnmount = addJobListener(socketInstance)("connectError");
    const disconnectUnmount = addJobListener(socketInstance)("disconnect");
    const exceptionUnmount = addJobListener(socketInstance)("exception");

    return () => {
      connectUnmount();
      connectErrorUnmount();
      disconnectUnmount();
      exceptionUnmount();
    };
  };

  useDidMount(() => {
    const socketInstance: Socket<Md5JobsListenerHandlers> = io(appEnvironment.apiUrl + SOCKET_MD5_JOBS_NAMESPACE, {
      autoConnect: false,
      extraHeaders: {
        authorization: `Bearer ${token}`,
      },
    });

    const unmountListeners = mountListeners(socketInstance);
    socketInstance.connect();

    setSocket(socketInstance);

    return () => {
      socketInstance.disconnect();
      unmountListeners();
    };
  });

  return { socket, isConnected };
};
