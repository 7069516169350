import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Users page</h2>
    <p>
      The Users List page allows admins to manage all users within the Heiser app. The app uses Google Authentication
      for sign-in.
    </p>
    <p>
      Not every user from the Google Auth group is automatically allowed access. Access is controlled through a
      whitelist, where only approved users can log in. The page contains two tabs: one for viewing signed-in users and
      another for managing the whitelist of allowed users.
    </p>
  </div>
);

const secondStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Signed in users</h2>
    <p>
      The Users tab displays a list of all signed-in users, showing their email addresses, roles (e.g., admin or user),
      and active status. Admins can manage each user&apos;s role and activation to control their access and permissions
      within the Heiser app.
    </p>
  </div>
);

const thirdStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Whitelist tab</h2>
    <p>The Whitelist Tab lets admins switch the view from the list of signed-in users to the whitelist.</p>
    <p>This section shows the users who are pre-approved to enter the app, even if they haven&apos;t signed in yet.</p>
  </div>
);

export const steps = ({ whitelistClick }: { whitelistClick: () => void }): TourStepOption[] => [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".users-list-navbar", on: "bottom-start" },
    permission: ["read", "users"],
  },
  {
    text: renderToString(secondStep),
    attachTo: { element: ".table", on: "top" },
    permission: ["read", "users"],
  },
  {
    text: renderToString(thirdStep),
    attachTo: { element: ".whitelist-tab", on: "top" },
    permission: ["create", "suppressions"],
    buttonText: "Next",
    onNextClick: whitelistClick,
  },
];
