import { client } from "api/client.api";
import { CompanyModel, List } from "models";
import { CompanyData, UpdateCompanyData } from "./companies.types";
import { DeleteResponse, ServerErrorType } from "api/api.types";
import { BasePaginationQuery, BaseSearchQuery } from "types";

export const createCompany = client.createRequest<CompanyModel, CompanyData>()({
  method: "POST",
  endpoint: "/companies",
});

export const getCompanies = client.createRequest<
  List<CompanyModel>,
  null,
  ServerErrorType,
  BaseSearchQuery & BasePaginationQuery
>()({
  method: "GET",
  endpoint: "/companies",
  options: { timeout: 0 },
});

export const getActiveCompanies = client.createRequest<
  CompanyModel[],
  null,
  ServerErrorType,
  BaseSearchQuery & BasePaginationQuery
>()({
  method: "GET",
  endpoint: "/companies/active",
  options: { timeout: 0 },
});

export const getCompany = client.createRequest<CompanyModel, null, ServerErrorType>()({
  method: "GET",
  endpoint: "/companies/:companyId",
  options: { timeout: 0 },
});

export const updateCompany = client.createRequest<CompanyModel, UpdateCompanyData>()({
  method: "PUT",
  endpoint: "/companies/:companyId",
});

export const deleteCompany = client.createRequest<DeleteResponse>()({
  endpoint: "/companies/:companyId",
  method: "DELETE",
});
