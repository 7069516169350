import { object, string, Schema } from "yup";

import { CreateOptizmoOfferFormData } from "./create-optizmo-offer.types";

export const validationSchema: Schema<CreateOptizmoOfferFormData> = object().shape({
  name: string().required("Name is required"),
  accessKey: string(),
});

export const initialValues: CreateOptizmoOfferFormData = {
  name: "",
  accessKey: "",
};
