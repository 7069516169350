import React from "react";
import { Button, FormField, Label } from "@epcnetwork/core-ui-kit";
import { useFormikContext } from "formik";

import { IterableProjectData } from "api";

import { Trash } from "assets/icons";

import styles from "./projects-creator.module.scss";

export function ProjectsCreator<T extends { projects: IterableProjectData[] }>() {
  const { values, setFieldValue } = useFormikContext<T>();

  return (
    <div className={styles.wrapper}>
      {values.projects.map((project, index) => {
        const handleDelete = async () => {
          const projects = values?.projects || [];
          projects.splice(index, 1);
          await setFieldValue("projects", projects);
        };

        return (
          <div key={index}>
            <p className={styles.projectName}>{values.projects[index].name || `Project #${index + 1}`}</p>
            <div className={styles.container}>
              <FormField
                type="text"
                name={`projects[${index}].name`}
                label="Project name"
                className={styles.input}
                inputSize="small"
                disableError
              />
              <FormField
                type="text"
                name={`projects[${index}].apiKey`}
                label="API key"
                className={styles.input}
                inputSize="small"
                disableError
              />
              <Button type="button" imageBtn appearance="text" className={styles.removeButton} onClick={handleDelete}>
                <Trash />
              </Button>
            </div>
            <div className={styles.labelsWrapper}>
              <div className={styles.labelRow}>
                <FormField type="switch" name={`projects[${index}].enableUnforget`} disableError />
                <div>
                  <Label text="Unforget" isInputLabel />
                  <p className={styles.labelSpan}>
                    The Unforget switch removes forgotten emails after 90 days, provided no complaints have been
                    received from the email during that time.
                  </p>
                </div>
              </div>
              <div className={styles.labelRow}>
                <FormField type="switch" name={`projects[${index}].enableComplaintsForwarding`} disableError />
                <div>
                  <Label text="Complaints forwarding" isInputLabel />
                  <p className={styles.labelSpan}>
                    The complaints forwarding switch automatically forwards emails to Salesforce when a complaint is
                    received.
                  </p>
                </div>
              </div>
              <div className={styles.labelRow}>
                <FormField type="switch" name={`projects[${index}].mergexImport`} disableError />
                <div>
                  <Label text="Import Mergex suppression list" isInputLabel />
                  <p className={styles.labelSpan}>
                    Import suppressions list from Mergex and forget them on Iterable projects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
