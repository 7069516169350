import React, { FC, useRef } from "react";
import { TableRow, TableCell, TextEllipsis, formatDate } from "@epcnetwork/core-ui-kit";

import { AuditLogModel } from "models";
import { actions, renderTypeLabel } from "../audit-logs-list.constants";
import { Badge } from "components";

import { Calendar, Clock } from "assets/icons";

import styles from "./audit-logs-item.module.scss";

type AuditLogsItemProps = {
  auditLog: AuditLogModel;
  onItemClick: (audit: AuditLogModel) => void;
};

export const AuditLogsItem: FC<AuditLogsItemProps> = ({ auditLog, onItemClick }) => {
  const terminateButtonRef = useRef<HTMLDivElement>(null);

  const handleRowClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const excludeElements = [terminateButtonRef.current];

    const containsExcluded = excludeElements.some((element) => element?.contains(e.target as HTMLElement));
    const isExcluded = excludeElements.some((element) => element === e.target);
    if (!containsExcluded && !isExcluded) {
      onItemClick(auditLog);
    }
  };

  return (
    <TableRow id={auditLog.id} onRowClick={handleRowClick} className={styles.tableRow}>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {auditLog.email}
        </TextEllipsis>
      </TableCell>

      <TableCell className={styles.cellRow}>
        <div className={styles.row}>
          <Calendar />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(auditLog.date, "EEE, MMM dd, yyyy")}
          </TextEllipsis>
        </div>
        <div className={styles.row}>
          <Clock />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(auditLog.date, "HH:mm:ss")}
          </TextEllipsis>
        </div>
      </TableCell>

      <TableCell>
        <Badge variant={actions[auditLog.action].variant}>
          {actions[auditLog.action].icon} {auditLog.action.toUpperCase()}
          {renderTypeLabel(auditLog?.type)}
        </Badge>
      </TableCell>
      <TableCell>{auditLog.name}</TableCell>
      <TableCell>
        <span></span>
      </TableCell>
    </TableRow>
  );
};
