import React from "react";
import { Button, FormButtons, FormField, MessageField, Modal, notification } from "@epcnetwork/core-ui-kit";
import { Form, Formik } from "formik";
import { useSubmit } from "@hyper-fetch/react";

import { initialValues, validationSchema } from "./create-whitelist-item.constants";
import { createWhitelistItem, CreateWhitelistItemData } from "api";
import { WhitelistItemModel } from "models";

import styles from "./create-whitelist-item.module.scss";

interface Props {
  isOpen: boolean;
  close: () => void;
  onCreateSuccess: (item: WhitelistItemModel) => void;
}

export const CreateWhitelistItem: React.FC<Props> = ({ isOpen, close, onCreateSuccess }) => {
  const { submit, error, onSubmitSuccess, onSubmitError } = useSubmit(createWhitelistItem);
  onSubmitSuccess(({ response }) => {
    onCreateSuccess(response);
    close();
    notification.success("Whitelist user created!", "User has access to the Heiser.");
  });
  onSubmitError(() => {
    notification.error("Whitelist user create error!", "Error occurred while adding the user to the whitelist.");
  });

  const handleSubmit = async (values: CreateWhitelistItemData) => {
    await submit({ data: values });
  };

  return (
    <Modal isOpen={isOpen} setClose={close} contentClassName={styles.modal}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        <Form>
          <div className={styles.title}>Add user to whitelist</div>
          <MessageField message={error?.message || ""} />
          <div className={styles.content}>
            <FormField
              type="text"
              name="email"
              label="Email"
              placeholder="name@email.com"
              required
              autoComplete="off"
            />
          </div>
          <FormButtons>
            <Button appearance="secondary" onClick={close}>
              Cancel
            </Button>
            <Button type="submit">Submit</Button>
          </FormButtons>
        </Form>
      </Formik>
    </Modal>
  );
};
