import { FC } from "react";
import {
  TableRow,
  TableCell,
  Menu,
  MenuButton,
  notification,
  TextEllipsis,
  useWindowSize,
  formatDate,
} from "@epcnetwork/core-ui-kit";
import { useSubmit } from "@hyper-fetch/react";
import { useSelector } from "react-redux";

import { WhitelistItemModel } from "models";
import { usePermissions } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import { deleteWhitelistItem } from "api";
import { Calendar, Clock } from "assets";
import { DeleteResponse } from "api/api.types";
import { Badge } from "components";
import { RootState } from "store";

import styles from "./whitelist-table-item.module.scss";

type UsersListTableRowProps = {
  index: number;
  item: WhitelistItemModel;
  handleDeleteSuccess: (item: DeleteResponse) => void;
};

export const WhitelistTableItem: FC<UsersListTableRowProps> = ({ index, item, handleDeleteSuccess }) => {
  const { width } = useWindowSize();
  const { id, email, createdAt } = item;
  const { user } = useSelector((state: RootState) => state.auth);

  const [canDeleteUser] = usePermissions(["create", "delete"], "users");

  const { submit, onSubmitSuccess, onSubmitError } = useSubmit(deleteWhitelistItem);
  onSubmitSuccess(({ response }) => {
    handleDeleteSuccess(response);
    notification.success("User removed from whitelist", "User has been successfully removed from whitelist");
  });
  onSubmitError(() => {
    notification.error("Whitelist user error", "Cannot remove user from whitelist");
  });

  const handleUserDelete = () => {
    notification.confirm(
      "Do you want to remove this user from whitelist?",
      item.userId
        ? "An account has been assigned to this email, which means that this user will not be able to log in and the account will be deactivated."
        : "No account has been assigned to this email, user with this email will not be able to log in to Heiser.",
      {
        onOk: async () => {
          await submit({ params: { recordId: id } });
        },
      },
    );
  };

  const canDeleteWhitelistItem = canDeleteUser && user?.id !== item?.userId;

  return (
    <TableRow id={index}>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {email}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.cellRow}>
        <div className={styles.row}>
          <Calendar />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAt, "EEE, MMM dd, yyyy")}
          </TextEllipsis>
        </div>
        <div className={styles.row}>
          <Clock />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAt, "HH:mm:ss")}
          </TextEllipsis>
        </div>
      </TableCell>
      <TableCell className={styles.cellRow}>
        <Badge variant={item?.userId ? "success" : "default"}>{item?.userId ? "Signed up" : "Pending"}</Badge>
      </TableCell>
      <TableCell>
        {canDeleteWhitelistItem && (
          <Menu>
            <MenuButton appearance="delete" onClick={handleUserDelete} icon="delete">
              Delete
            </MenuButton>
          </Menu>
        )}
      </TableCell>
    </TableRow>
  );
};
