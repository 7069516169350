import { boolean, object, Schema } from "yup";

import { emailColumnValidation, headersValidation } from "utils";
import { requiredFieldText } from "constants/form.constants";
import { Md5Values } from "./md5-import-values.types";

export const validationSchema: Schema<Md5Values> = object().shape({
  headerValues: object().shape({
    emailIndex: emailColumnValidation(),
    headers: headersValidation(),
    hasHeaders: boolean().required(requiredFieldText),
  }),
});
