import { object, Schema, string } from "yup";

import { CreateWhitelistItemData } from "api";

export const validationSchema: Schema<CreateWhitelistItemData> = object().shape({
  email: string().email("Email is invalid").required("Email is required"),
});

export const initialValues: CreateWhitelistItemData = {
  email: "",
};
