import { useMemo } from "react";
import Shepherd, { StepOptions } from "shepherd.js";
import { useSelector } from "react-redux";
import { useDidMount, useDidUpdate } from "@better-hooks/lifecycle";
import { useLocalStorage } from "@epcnetwork/core-ui-kit";

import { RootState } from "store";
import { PermissionTuple } from "models";
import { usePermissionCheck } from "hooks/use-permission";

export type TourStepOption = StepOptions & {
  permission: PermissionTuple;
  onNextClick?: () => void;
  buttonText?: string;
};

export const useTour = ({ name, steps }: { name: string; steps: TourStepOption[] }) => {
  const { checkPermissionTuple } = usePermissionCheck();
  const { user } = useSelector((state: RootState) => state.auth);
  const { value: tourAlreadySeen, setValue } = useLocalStorage<boolean>(`${name}-tour-seen`);

  const tourObject = useMemo(() => {
    const tourInstance = new Shepherd.Tour({ useModalOverlay: true });

    const allowedSteps = steps.filter((step) => checkPermissionTuple(step.permission));

    const mappedSteps: TourStepOption[] = allowedSteps.map(({ buttonText, onNextClick, ...step }, index) => {
      const isFirst = index === 0;
      const isLastStep = index === allowedSteps.length - 1;

      return {
        ...step,
        modalOverlayOpeningPadding: 8,
        modalOverlayOpeningRadius: { topLeft: 12, bottomLeft: 12, bottomRight: 12, topRight: 12 },
        buttons: [
          ...(isLastStep
            ? []
            : [
                {
                  classes: "cancel",
                  text: "Skip",
                  type: "cancel",
                  secondary: true,
                  action: () => {
                    tourInstance.cancel();
                    setValue(true);
                  },
                },
              ]),
          ...(isFirst
            ? []
            : [
                {
                  classes: "back",
                  text: "Back",
                  type: "back",
                  action: () => tourInstance.back(),
                },
              ]),
          {
            classes: "next",
            text: buttonText ? buttonText : isLastStep ? "Close" : "Next",
            type: "next",
            action: () => {
              onNextClick?.();
              if (isLastStep) {
                tourInstance.cancel();
                setValue(true);
              } else {
                tourInstance.next();
              }
            },
          },
        ],
      };
    });

    tourInstance.addSteps(mappedSteps);

    return tourInstance;
  }, [steps]);

  useDidUpdate(
    () => {
      if (tourAlreadySeen === null) return;

      if (user?.isFirstLogin && !tourAlreadySeen) {
        tourObject.start();
      }

      return () => tourObject.cancel();
    },
    [user, tourAlreadySeen],
    true,
  );

  useDidMount(() => {
    return () => tourObject.cancel();
  });

  return tourObject;
};
