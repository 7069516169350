import { client } from "api/client.api";
import { BusinessUnitModel, List } from "models";
import {
  BusinessUnitsResponse,
  CreateBusinessUnitData,
  CreateBusinessUnitWithUpload,
  DataExtensionFieldsData,
  DataExtensionFieldsResponse,
  GetBusinessUnitsQuery,
  SalesforceDataExtensionsResponse,
  SalesforceListsResponse,
  UpdateBusinessUnitData,
} from "./business-units.types";
import { DeleteResponse, ServerErrorType } from "api/api.types";
import { fileUploadMiddleware } from "api/file-upload.api.middleware";

export const createBusinessUnit = client.createRequest<BusinessUnitsResponse, CreateBusinessUnitData>()({
  method: "POST",
  endpoint: "/business-units",
});

export const createBusinessUnitWithFile = fileUploadMiddleware<undefined, CreateBusinessUnitWithUpload>()({
  method: "post",
  endpoint: "/business-units/form",
  isFormData: true,
});

export const getBusinessUnits = client.createRequest<
  List<BusinessUnitsResponse>,
  null,
  ServerErrorType,
  GetBusinessUnitsQuery
>()({
  method: "GET",
  endpoint: "/business-units",
  options: { timeout: 0 },
});

export const getActiveBusinessUnits = client.createRequest<BusinessUnitsResponse[], null, ServerErrorType>()({
  method: "GET",
  endpoint: "/business-units/active",
  options: { timeout: 0 },
});

export const getBusinessUnit = client.createRequest<BusinessUnitModel>()({
  method: "GET",
  endpoint: "/business-units/:businessUnitId",
  options: { timeout: 0 },
});

export const getAllActiveDataExtensions = client.createRequest<SalesforceDataExtensionsResponse[]>()({
  method: "GET",
  endpoint: "/business-units/:businessUnitId/data-extensions",
  options: { timeout: 0 },
});

export const getActiveLists = client.createRequest<SalesforceListsResponse[]>()({
  method: "GET",
  endpoint: "/business-units/:businessUnitId/lists",
  options: { timeout: 0 },
});

export const getDataExtensionFields = client.createRequest<DataExtensionFieldsResponse[], DataExtensionFieldsData>()({
  method: "POST",
  endpoint: "/business-units/:businessUnitId/data-extension-fields",
  options: { timeout: 0 },
});

export const updateBusinessUnit = client.createRequest<BusinessUnitsResponse, UpdateBusinessUnitData>()({
  method: "PUT",
  endpoint: "/business-units/:businessUnitId",
});

export const deleteBusinessUnit = client.createRequest<DeleteResponse>()({
  endpoint: "/business-units/:businessUnitId",
  method: "DELETE",
});
