import { object, string, Schema } from "yup";

import { CreateBusinessUnitFormData } from "api";

export const validationSchema: Schema<CreateBusinessUnitFormData> = object().shape({
  name: string().required("Name is required"),
  clientId: string().required("Client ID is required"),
  clientSecret: string().required("Client secret is required"),
  authUrl: string().required("Auth URL is required"),
  accountId: string().matches(/^\d+$/, "Account ID should have digits only").required("Account ID is required"),
});

export const initialValues: CreateBusinessUnitFormData = {
  name: "",
  clientId: "",
  clientSecret: "",
  authUrl: "",
  accountId: "",
};
