import { FC, useRef } from "react";
import {
  TableRow,
  TableCell,
  Menu,
  MenuButton,
  notification,
  TextEllipsis,
  Switch,
  Checkbox,
} from "@epcnetwork/core-ui-kit";
import { useSubmit } from "@hyper-fetch/react";

import { usePermissions } from "hooks";
import { deleteOffers, updateOffer } from "api";
import { DeleteResponse } from "api/api.types";
import { OfferModel } from "models";

import styles from "./optizmo-offer-item.module.scss";

type OptizmoCompanyItemProps = {
  index: number;
  offer: OfferModel;
  handleEdit: (company: OfferModel) => void;
  onRemoveSuccess: (response: DeleteResponse) => void;
  onActivateSuccess: (company: OfferModel) => void;
  isSelected: boolean;
  onSelect: (id: number, checked: boolean) => void;
  active?: boolean;
};

export const OptizmoOfferItem: FC<OptizmoCompanyItemProps> = ({
  index,
  offer,
  handleEdit,
  onRemoveSuccess,
  onActivateSuccess,
  isSelected,
  onSelect,
  active,
}) => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const switchRef = useRef<HTMLDivElement | null>(null);

  const [canUpdateAccount, canDeleteAccount] = usePermissions(["update", "delete"], "accounts");

  const remove = useSubmit(deleteOffers);
  remove.onSubmitSuccess(({ response }) => {
    onRemoveSuccess(response[0]);
    notification.success("Optizmo offer deleted", "Optizmo offer successfully deleted");
  });
  remove.onSubmitError(() => {
    notification.error("Delete error", "Cannot delete this Optizmo offer.");
  });

  const update = useSubmit(updateOffer);
  update.onSubmitSuccess(({ response }) => {
    onActivateSuccess(response);
    notification.success(
      response.isActive ? "Optizmo offer activated" : "Optizmo offer deactivated",
      response.isActive ? "Optizmo offer is now active." : "Optizmo offer is deactivated.",
    );
  });
  update.onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the company");
  });

  const handleRemoveItem = () => {
    notification.confirm("Do you want to delete this Optizmo offer?", "This action is irreversible!", {
      onOk: async () => {
        await remove.submit({ data: { ids: [offer.id] } });
      },
    });
  };

  const handleEditItem = () => handleEdit(offer);

  const handleCheckboxClick = (value: string, isChecked: boolean) => {
    onSelect(offer.id, isChecked);
  };

  const handleSwitchChange = async (value: string, checked: boolean) => {
    await update.submit({ data: { isActive: checked }, params: { offerId: offer.id } });
  };

  return (
    <TableRow id={offer.id} className={styles.row}>
      <TableCell className={styles.firstCell}>
        <div className={styles.checkboxCell}>
          <Checkbox
            value={offer.id}
            onChange={handleCheckboxClick}
            onClick={(e) => e.stopPropagation()}
            className={styles.checkbox}
            checked={isSelected}
            name={`offer-${offer.id}`}
            inputSize="big"
            disableError
            showBox
          />
        </div>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {offer.name}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <div className={styles.switch} ref={switchRef}>
          <Switch
            value="toggle"
            checked={offer.isActive}
            disableError
            onChange={handleSwitchChange}
            disabled={active === false}
          />
        </div>
      </TableCell>

      <TableCell>
        <div ref={menuRef} data-testid={`iterable-menu-button-${index}`}>
          <Menu portalClassName={`iterable-menu-${index}`}>
            {canUpdateAccount && (
              <MenuButton onClick={handleEditItem} icon="edit" data-testid={`iterable-edit-${index}`}>
                Edit
              </MenuButton>
            )}
            {canDeleteAccount && (
              <MenuButton
                appearance="delete"
                onClick={handleRemoveItem}
                icon="delete"
                data-testid={`iterable-delete-${index}`}
              >
                Delete
              </MenuButton>
            )}
            {!canUpdateAccount && !canDeleteAccount && <div style={{ padding: "10px" }}>No Actions available</div>}
          </Menu>
        </div>
      </TableCell>
    </TableRow>
  );
};
