import { object, Schema, boolean } from "yup";

import { UserModel } from "models";
import { UpdateUserFormData } from "./update-user.types";

export const validationSchema: Schema<UpdateUserFormData> = object().shape({
  isAdmin: boolean().required("Role is required"),
});

export const initialValues = (user: UserModel | null): UpdateUserFormData => ({
  isAdmin: user?.isAdmin || false,
});
