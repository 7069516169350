import { useNavigate } from "react-router-dom";
import { FC } from "react";
import { useSelector } from "react-redux";

import { LOGOUT_PAGE, SETTINGS_PAGE } from "constants/routes.constants";
import { RootState } from "store";
import { Exit, Settings } from "assets";

import styles from "components/layout/navbar/navbar.module.scss";

interface Props {
  closeMenu: () => void;
}

export const Menu: FC<Props> = ({ closeMenu }) => {
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.auth);

  const handleLogout = () => navigate(LOGOUT_PAGE.path);
  const handleSettings = () => {
    navigate(SETTINGS_PAGE.path);
    closeMenu();
  };

  return (
    <div className={styles.menu} data-testid="navbar-menu">
      <div className={styles.name}>
        <span>{user?.email}</span>
      </div>
      <button className={styles.item} onClick={handleSettings}>
        <Settings />
        Settings
      </button>
      <button className={styles.item} onClick={handleLogout}>
        <Exit />
        Sign out
      </button>
    </div>
  );
};
