import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>MD5 jobs list page</h2>
    <p>
      The Jobs page displays a complete list of all export jobs, allowing you to track the progress and status of each
      job. Here, you can monitor export activity and quickly access completed jobs for download.
    </p>
  </div>
);

const secondStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Jobs list</h2>
    <p>The export jobs table automatically refreshes, ensuring you see the latest job statuses in real time.</p>
    <p>
      Each job entry includes a status indicator and a convenient button to download the generated CSV file once the
      export is complete, streamlining access to your data.
    </p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".md5-job-navbar", on: "bottom-start" },
    permission: ["read", "md5"],
  },
  {
    text: renderToString(secondStep),
    attachTo: { element: ".table", on: "top" },
    permission: ["read", "md5"],
  },
];
