import { client } from "api/client.api";
import { DataExtensionFromApi, DataExtensionModel, List } from "models";
import { DeleteResponse, ServerErrorType } from "api/api.types";
import {
  CreateDataExtensionsData,
  CreateDataExtensionsWithUpload,
  GetDataExtensionsQuery,
  UpdateDataExtensionData,
} from "./data-extensions.types";
import { fileUploadMiddleware } from "api/file-upload.api.middleware";

export const createDataExtension = client.createRequest<DataExtensionModel[], CreateDataExtensionsData>()({
  method: "POST",
  endpoint: "/data-extensions",
});

export const createDataExtensionsWithFile = fileUploadMiddleware<undefined, CreateDataExtensionsWithUpload>()({
  method: "post",
  endpoint: "/data-extensions/form",
  isFormData: true,
});

export const getDataExtensions = client.createRequest<
  List<DataExtensionModel>,
  null,
  ServerErrorType,
  Partial<GetDataExtensionsQuery>
>()({
  method: "GET",
  endpoint: "/data-extensions",
  options: { timeout: 0 },
});

export const getActiveDataExtensions = client.createRequest<DataExtensionModel[]>()({
  method: "GET",
  endpoint: "/data-extensions/active",
  options: { timeout: 0 },
});

export const getApiDataExtensions = client.createRequest<
  List<DataExtensionFromApi>,
  null,
  ServerErrorType,
  Partial<GetDataExtensionsQuery>
>()({
  method: "GET",
  endpoint: "/data-extensions/fetch-from-api",
  options: { timeout: 0 },
});

export const getDataExtension = client.createRequest<DataExtensionModel[]>()({
  method: "GET",
  endpoint: "/data-extensions/:dataExtensionId",
  options: { timeout: 0 },
});

export const updateDataExtension = client.createRequest<DataExtensionModel, UpdateDataExtensionData>()({
  method: "PUT",
  endpoint: `/data-extensions/:dataExtensionId`,
});

export const deleteDataExtension = client.createRequest<DeleteResponse[], { ids: number[] }>()({
  method: "DELETE",
  endpoint: "/data-extensions",
  options: { timeout: 0 },
});
