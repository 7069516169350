import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Optizmo clients page</h2>
    <p>
      The clients list page allows you to manage all clients connected to your organization, displaying each client’s
      name and activation status.
    </p>
  </div>
);

const secondStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Create Optizmo client button</h2>
    <p>
      To add a new client, simply click the Add Client button. You’ll be asked to enter the client’s name, which will
      appear in the list once saved.
    </p>
  </div>
);

const thirdStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Clients list</h2>
    <p>The list shows each client’s name and active status.</p>
    <p>
      With the activation switch, you can quickly enable or disable any client as needed, helping you to easily maintain
      control over your client list.
    </p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".optizmo-clients-navbar", on: "bottom-start" },
    permission: ["read", "integrations"],
  },
  {
    text: renderToString(secondStep),
    attachTo: { element: ".add-optizmo-client", on: "top" },
    permission: ["create", "integrations"],
  },
  {
    text: renderToString(thirdStep),
    attachTo: { element: ".table", on: "top" },
    permission: ["read", "integrations"],
  },
];
