import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { BasePaginationQuery, BaseSearchQuery, ColumnsType } from "types";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "constants/query.constants";

export const salesforceAccountsColumns: ColumnsType = [
  { label: "Name", required: true, queryKey: "name" },
  { label: "Client ID", queryKey: "clientId" },
  { label: "Auth URL" },
  { label: "Account ID", queryKey: "accountId" },
  { label: "Data extensions" },
  { label: "Active" },
  { label: "" },
];

export const TABLE_NAME = "business-units-table";

export const initialFilters: UseFiltersProps<BaseSearchQuery & BasePaginationQuery> = {
  initialState: {
    search: "",
    limit: DEFAULT_LIMIT,
    offset: DEFAULT_OFFSET,
  },
};
