import React, { useState } from "react";
import { FormField, Label, SelectOption } from "@epcnetwork/core-ui-kit";
import { useFormikContext } from "formik";
import { useSubmit } from "@hyper-fetch/react";
import { useDidMount, useDidUpdate } from "@better-hooks/lifecycle";

import { OptizmoExportData } from "pages/md5/export/md5-export.types";
import { getActiveClients, getActiveCompanies, getActiveOffers } from "api";

import styles from "pages/md5/export/md5-export.module.scss";

export const OptizmoSelector: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<OptizmoExportData>();

  const [companiesOptions, setCompaniesOptions] = useState<SelectOption<number>[]>([]);
  const [clientsOptions, setClientsOptions] = useState<SelectOption<number>[]>([]);
  const [offersOptions, setOffersOptions] = useState<SelectOption<number>[]>([]);

  const activeCompanies = useSubmit(getActiveCompanies);
  activeCompanies.onSubmitSuccess(({ response }) => {
    const options: SelectOption<number>[] = response.map((company) => ({
      label: company.name,
      value: company.id,
    }));
    setCompaniesOptions(options);
  });

  const activeClients = useSubmit(getActiveClients);
  activeClients.onSubmitSuccess(({ response }) => {
    const options: SelectOption<number>[] = response.map((client) => ({
      label: client.name,
      value: client.id,
    }));
    setClientsOptions(options);
  });

  const activeOffers = useSubmit(getActiveOffers);
  activeOffers.onSubmitSuccess(({ response }) => {
    const options: SelectOption<number>[] = response.map((offer) => ({
      label: offer.name,
      value: offer.id,
    }));
    setOffersOptions(options);
  });

  useDidMount(() => {
    activeCompanies.submit();
  });

  useDidUpdate(() => {
    setFieldValue("clientId", 0);
    setFieldValue("offerId", 0);

    if (values.companyId) {
      activeClients.submit({ queryParams: { companyId: values.companyId.toString() } });
    }
  }, [values.companyId]);

  useDidUpdate(() => {
    setFieldValue("offerId", 0);

    if (values.clientId) {
      activeOffers.submit({ queryParams: { clientId: values.clientId.toString() } });
    }
  }, [values.clientId]);

  return (
    <div className={`${styles.container} offer-selector`}>
      <div>
        <div className={styles.row}>
          <div className={styles.step}>1</div>
          <div>
            <Label text="Company" isInputLabel />
            <p className={styles.projectDescription}>Select a company</p>
          </div>
        </div>
        <FormField
          type="select"
          name="companyId"
          options={companiesOptions}
          isSearchable
          asyncOptions={{
            loading: activeCompanies.submitting,
          }}
          searchPlaceholder="Search projects"
          isMulti={false}
        />
      </div>

      <div>
        <div className={styles.row}>
          <div className={styles.step}>2</div>
          <div>
            <Label text="Client" isInputLabel />
            <p className={styles.projectDescription}>Select a client</p>
          </div>
        </div>
        <FormField
          type="select"
          name="clientId"
          options={clientsOptions}
          isSearchable
          searchPlaceholder="Search clients"
          disabled={!values.companyId || activeCompanies.submitting}
          asyncOptions={{
            loading: activeClients.submitting,
          }}
          isMulti={false}
        />
      </div>

      <div>
        <div className={styles.row}>
          <div className={styles.step}>3</div>
          <div>
            <Label text="Offer" isInputLabel />
            <p className={styles.projectDescription}>Select an offer</p>
          </div>
        </div>
        <FormField
          type="select"
          name="offerId"
          options={offersOptions}
          isSearchable
          asyncOptions={{
            loading: activeOffers.submitting,
          }}
          disabled={!values.clientId || activeCompanies.submitting || activeClients.submitting}
          searchPlaceholder="Search offers"
          isMulti={false}
        />
      </div>
    </div>
  );
};
