import React from "react";

import styles from "./progress.module.scss";

interface Props {
  current: string | number;
  total: number;
}

export const Progress: React.FC<Props> = ({ current, total }) => {
  const percentage = Math.round((+current / total) * 100);

  const width = percentage < 100 ? `${percentage}%` : "100%";

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.inner} style={{ width }} />
      </div>
      <div className={styles.percentage}>
        {percentage}%
        <span className={styles.values}>
          ({current}/{total})
        </span>
      </div>
    </div>
  );
};
