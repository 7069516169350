import React, { FC } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form } from "formik";
import { Button, MessageField, FormField, notification, Modal } from "@epcnetwork/core-ui-kit";

import { CompanyData, createCompany } from "api";
import { CreateOptizmoCompanyProps } from "./create-optizmo-company.types";
import { validationSchema, initialValues } from "./create-optizmo-company.constants";

import styles from "../create-optizmo.module.scss";

export const CreateOptizmoCompany: FC<CreateOptizmoCompanyProps> = ({ isOpen, close, refresh, onCreateSuccess }) => {
  const { submit, error, onSubmitSuccess, onSubmitError } = useSubmit(createCompany);
  onSubmitSuccess(({ response }) => {
    onCreateSuccess(response);
    close();
    notification.success("Optizmo company created!", "Optizmo company has been successfully created.");
  });
  onSubmitError(() => {
    notification.error("Create error!", "Error occurred while creating a new Optizmo company.");
  });

  const handleClose = () => {
    close();
  };

  const handleSubmit = async (values: CompanyData) => {
    const data = { ...values };
    if (!values?.optizmoToken) delete data.optizmoToken;

    await submit({ data });
  };

  return (
    <Modal isOpen={isOpen} setClose={handleClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ isValid }) => {
          return (
            <Form>
              <div className={styles.title}>Add Optizmo company</div>
              <MessageField message={error?.message || ""} />

              <FormField type="text" name="name" label="Company name" disableFloatingLabel required />
              <FormField type="text" name="optizmoToken" label="Optizmo token" disableFloatingLabel />

              <div className={styles.buttons}>
                <Button appearance="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!isValid}>
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
