import { FC } from "react";
import { useSelector } from "react-redux";

import { GoogleLogin } from "./google-login/google-login";
import { LoginSidebar } from "./login-sidebar/login-sidebar";
import { RootState } from "store";
import { MfaForm } from "./mfa-form/mfa-form";

import { HeiserLogo } from "assets/icons";
import background from "assets/icons/login-bg.svg";

import styles from "./login.module.scss";

const LoginPage: FC = () => {
  const { mfaTemporaryToken } = useSelector((state: RootState) => state.auth);

  return (
    <div className={styles.container}>
      <img src={background} className={styles.gradientBg} alt="Background" />
      <div className={styles.logo}>
        <div className={styles.logoWrapper}>
          <HeiserLogo />
          <span className={styles.logoText}>Heiser</span>
        </div>
      </div>
      <LoginSidebar />
      <div className={styles.loginForm}>
        <div className={styles.formTitle}>Welcome to Heiser</div>
        <div className={styles.formSubtitle}>Please use your Google account to sign in</div>
        {!mfaTemporaryToken && <GoogleLogin />}
        {!!mfaTemporaryToken && <MfaForm temporaryToken={mfaTemporaryToken} />}
      </div>
    </div>
  );
};

export { LoginPage };
