import { client } from "api/client.api";
import { AuditLogModel, List } from "models";
import { ServerErrorType } from "api/api.types";
import { AuditLogsQueryParams } from "./audit-logs.types";

export const getAuditLogs = client.createRequest<List<AuditLogModel>, null, ServerErrorType, AuditLogsQueryParams>()({
  method: "GET",
  endpoint: "/user-actions",
  options: { timeout: 0 },
});
