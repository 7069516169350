import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { ColumnsType } from "types";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "constants/query.constants";
import { GetProjectsQuery } from "api";
import { SEARCH_MIN_LENGTH } from "constants/form.constants";

export const iterableProjectsColumns: ColumnsType = [
  { label: "Name", required: true, queryKey: "name" },
  {
    label: "Active",
    info: "Enable or disable project, ensuring it is excluded from suppressions when turned off.",
  },
  {
    label: "Enable unforget",
    info: "Enable or disable 'unforgetting' emails after 90 days, provided no complaints have been received from the email during that time.",
  },
  {
    label: "Enable complaints forwarding",
    info: "Enable or disable automatic emails forwarding to Salesforce when a complaint is received.",
  },
  { label: "" },
];

export const TABLE_NAME = "projects-table";

export const initialFilters: UseFiltersProps<GetProjectsQuery> = {
  searchOptions: { searchMinLength: SEARCH_MIN_LENGTH, searchDebounce: 1200, searchKey: "search" },
  initialState: {
    search: "",
    accountId: "",
    limit: DEFAULT_LIMIT,
    offset: DEFAULT_OFFSET,
  },
};
