import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Audit logs page</h2>
    <p>
      The Audit Logs page provides a complete history of actions performed within the Heiser app, allowing admins to
      monitor and track user activity.
    </p>
    <p>
      The page logs key actions such as create, update, delete, and user login events, giving you full visibility into
      all significant changes and interactions across the system.
    </p>
  </div>
);

const secondStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Audit logs list</h2>

    <p>
      The list displays all logged actions, showing the user who performed the action, the date and time it occurred,
      and the specific action taken.
    </p>

    <p>This helps admins maintain accountability and ensures a clear audit trail of user activity in the app.</p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".audit-logs-navbar", on: "bottom-start" },
    permission: ["read", "jobs"],
  },
  {
    text: renderToString(secondStep),
    attachTo: { element: ".table", on: "top" },
    permission: ["read", "jobs"],
  },
];
