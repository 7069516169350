import { FC, useState } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form } from "formik";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { Button, MessageField, notification, Modal, useFileQueue } from "@epcnetwork/core-ui-kit";

import {
  CreateDataExtensionsData,
  createDataExtension,
  CreateDataExtensionsWithUpload,
  createDataExtensionsWithFile,
  getDataExtensions,
} from "api";
import { SalesforceDataExtensionSelector } from "components";
import { CreateDataExtensionsProps, DataExtensionsFormData } from "./create-data-extension.types";
import { validationSchema, initialValues, emptyProject } from "./create-data-extension.constants";
import { FileUpload } from "pages/integrations/form";
import { DataExtensionsCreator } from "./data-extensions-creator/data-extensions-creator";
import { IntegrationFileItem } from "types";
import { DataExtensionModel } from "models";

import styles from "../create-salesforce.module.scss";

export const CreateDataExtension: FC<CreateDataExtensionsProps> = ({
  isOpen,
  close,
  businessUnit,
  onCreateSuccess,
  refresh,
}) => {
  const [inputMode, setInputMode] = useState<"select" | "file">("select");
  const [dataExtensions, setDataExtensions] = useState<DataExtensionModel[]>([]);

  const fileQueue = useFileQueue<IntegrationFileItem, CreateDataExtensionsWithUpload>(
    "Suppression",
    createDataExtensionsWithFile,
  );

  const { submit, error, onSubmitSuccess, onSubmitError } = useSubmit(createDataExtension);
  onSubmitSuccess(({ response }) => {
    onCreateSuccess(response);
    close();
    notification.success("Data Extensions successfully imported!", "Data Extensions have been successfully imported.");
  });
  onSubmitError(() => {
    notification.error("Create error!", "Error occurred while creating the Data Extensions.");
  });

  const fetchDataExtensions = useSubmit(
    getDataExtensions.setQueryParams({ businessUnitId: businessUnit.id.toString() }),
  );
  fetchDataExtensions.onSubmitSuccess(({ response }) => {
    setDataExtensions(response.data);
  });

  const handleClose = () => {
    close();
    fileQueue.removeFiles(fileQueue.files.map((file) => file.id));
    fileQueue.clearEntity();
  };

  useDidUpdate(
    () => {
      if (isOpen && inputMode === "select" && !dataExtensions.length) fetchDataExtensions.submit();
    },
    [inputMode, isOpen],
    true,
  );

  useDidUpdate(() => {
    setInputMode("select");

    return () => {
      setDataExtensions([]);
    };
  }, [isOpen]);

  useDidUpdate(() => {
    const haveError = fileQueue.files.some((file) => Boolean(file.error));

    if (fileQueue.isEntityFinished && !haveError) {
      refresh();
      handleClose();
      notification.success(
        "Salesforce Data Extensions created!",
        "Salesforce Data Extensions have been successfully created.",
      );
    }

    if (fileQueue.isEntityFinished && haveError) {
      notification.error("Create error!", "Error occurred while creating the Data Extensions.");
    }
  }, [fileQueue.isEntityFinished]);

  const handleSubmit = async (values: DataExtensionsFormData) => {
    const formValues: CreateDataExtensionsData = { ...values, businessUnitId: businessUnit.id };

    const dataExtensions = formValues.dataExtensions.map(({ name, externalId }) => ({ name, externalId }));
    if (fileQueue.files.length === 0) return submit({ data: { ...formValues, dataExtensions } });

    const data = fileQueue.files.map((file) => {
      const formData = new FormData();

      const { hasHeaders, headers } = file.data;
      const nameIndex = headers.findIndex((header) => header === "name");
      const externalIdIndex = headers.findIndex((header) => header === "externalId");

      formData.append(
        "form",
        JSON.stringify({ hasHeaders, nameIndex, externalIdIndex, businessUnitId: businessUnit.id }),
      );
      formData.append("file", file.originalFile);

      return {
        id: file.id,
        data: formData,
      };
    });

    await fileQueue.submitOneByOne(data);
  };

  return (
    <Modal isOpen={isOpen} setClose={handleClose}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {({ values, setFieldValue, isValid, errors }) => {
          const handleFormInputMode = async () => {
            setInputMode("select");
            fileQueue.removeFiles(fileQueue.files?.[0]?.id);

            await setFieldValue("dataExtensions", [...values.dataExtensions, emptyProject]);
          };

          const handleUploadMode = async () => {
            if (inputMode === "file") return null;

            setInputMode("file");
            await setFieldValue("dataExtensions", []);
          };

          return (
            <Form>
              <div className={styles.title}>Create Salesforce data extensions</div>
              <span>Add data extensions to {businessUnit.name} Salesforce business unit.</span>

              <SalesforceDataExtensionSelector
                inputMode={inputMode}
                handleFormInput={handleFormInputMode}
                handleUploadInput={handleUploadMode}
              />

              <div>
                <p className={styles.labelDescription}>
                  {inputMode === "select"
                    ? "Select Data Extensions from a list below. Selected Data Extensions will be added to the application."
                    : "Upload CSV file with Data Extensions configuration."}
                </p>
              </div>
              {inputMode === "select" && <DataExtensionsCreator isOpen={isOpen} businessUnitId={businessUnit.id} />}
              {inputMode === "file" && (
                <FileUpload
                  title="CSV should contain Name and External ID column"
                  fileQueue={fileQueue}
                  integrationMode="salesforce"
                />
              )}

              {error && <MessageField message={error?.message || ""} />}

              <div className={styles.buttons}>
                <Button appearance="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!fileQueue.hasAllConfigured || !isValid}>
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
