import React from "react";
import cn from "classnames";

import { Skeleton } from "components";
import { Check, Circle, Failed, MinusCircle } from "assets";
import { Integration } from "types";

import styles from "./info-box.module.scss";

interface Props {
  integration: Integration;
  icon: React.ReactNode;
  description: string;
  valuesMode: "processed" | "currently";
  loading?: boolean;
  completed?: string | number;
  failed?: string | number;
  active?: string | number;
  delayed?: string | number;
  pending?: string | number;
  className?: string;
}

export const InfoBox: React.FC<Props> = ({
  integration,
  icon,
  completed,
  failed,
  active,
  delayed,
  pending,
  description,
  className,
  loading,
  valuesMode,
}) => {
  const integrationLabel: Record<Integration, string> = {
    iterable: "Iterable",
    salesforce: "Salesforce",
  };

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.labelWrapper}>
        <span className={styles.label}>{integrationLabel[integration]}</span>
        {icon}
      </div>
      <div className={styles.infoWrapper}>
        {valuesMode === "processed" && (
          <>
            <div>
              {loading && <Skeleton width="80px" height="30px" className={styles.skeleton} />}
              {!loading && (
                <h2 className={styles.title} data-testid={`${integration}-completed`}>
                  {completed}
                </h2>
              )}
              <div className={styles.row}>
                <Check className={styles.completed} />
                <span className={styles.completed}>Succeeded</span>
              </div>
            </div>
            <div>
              {loading && <Skeleton width="80px" height="30px" className={styles.skeleton} />}
              {!loading && (
                <h2 className={styles.title} data-testid={`${integration}-failed`}>
                  {failed}
                </h2>
              )}
              <div className={styles.row}>
                <Failed className={styles.failed} />
                <span className={styles.failed}>Failed</span>
              </div>
            </div>
          </>
        )}

        {valuesMode === "currently" && (
          <>
            <div>
              {loading && <Skeleton width="80px" height="30px" className={styles.skeleton} />}
              {!loading && (
                <h2 className={styles.title} data-testid={`${integration}-active`}>
                  {active}
                </h2>
              )}
              <div className={styles.row}>
                <Circle className={styles.completed} />
                <span className={styles.completed}>Active</span>
              </div>
            </div>
            <div>
              {loading && <Skeleton width="80px" height="30px" className={styles.skeleton} />}
              {!loading && (
                <h2 className={styles.title} data-testid={`${integration}-pending`}>
                  {pending}
                </h2>
              )}
              <div className={styles.row}>
                <Circle className={styles.waiting} />
                <span className={styles.waiting}>Waiting</span>
              </div>
            </div>
            <div>
              {loading && <Skeleton width="80px" height="30px" className={styles.skeleton} />}
              {!loading && (
                <h2 className={styles.title} data-testid={`${integration}-delayed`}>
                  {delayed}
                </h2>
              )}
              <div className={styles.row}>
                <MinusCircle className={styles.waiting} />
                <span className={styles.waiting}>Delayed</span>
              </div>
            </div>
          </>
        )}
      </div>
      <span className={styles.description}>{description}</span>
    </div>
  );
};
