import { useLocation, useNavigate } from "react-router-dom";
import React, { memo, useMemo, useState } from "react";
import { Button, Tooltip } from "@epcnetwork/core-ui-kit";
import cn from "classnames";

import { getActiveRoute } from "utils";
import { Menu } from "./menu/menu";
import { Avatar, Breadcrumbs, BreadcrumbsOption, Skeleton } from "components/ui";

import { XRoundedGrey, Hamburger, ArrowLeft } from "assets/icons";

import styles from "./navbar.module.scss";

type NavbarProps = {
  isMenuCollapsed: boolean;
  toggleSidebar: VoidFunction;
  name?: string;
  description?: string;
  nameAdornment?: React.ReactNode;
  hidePageName?: boolean;
  className?: string;
  showBackArrow?: boolean;
  backPage?: string;
  children?: React.ReactNode;
  loading?: boolean;
  navbarClassName?: string;
  breadcrumbs?: BreadcrumbsOption[];
};

export const Navbar = memo<NavbarProps>(
  ({
    toggleSidebar,
    isMenuCollapsed,
    loading,
    name,
    description,
    nameAdornment,
    hidePageName,
    children,
    backPage,
    className,
    showBackArrow,
    navbarClassName,
    breadcrumbs,
  }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

    const goBack = () => {
      if (backPage) {
        const query = location.state?.queryStringified || "";
        navigate(`${backPage}${query}`);
      } else {
        navigate(-1);
      }
    };

    const activeRoute = useMemo(() => getActiveRoute(location), [location]);

    return (
      <header data-testid="navbar" className={styles.contentWrapper}>
        {breadcrumbs && <Breadcrumbs options={breadcrumbs} />}
        <div className={cn(styles.container, className)}>
          <Button className={styles.hamburger} appearance="secondary" onClick={toggleSidebar} imageBtn>
            {isMenuCollapsed ? <Hamburger /> : <XRoundedGrey />}
          </Button>
          <div className={cn(styles.wrapper, navbarClassName)}>
            {!hidePageName && (
              <div>
                <div className={styles.titleWrapper}>
                  {(showBackArrow || backPage) && (
                    <Button imageBtn appearance="transparent" onClick={goBack} className={styles.arrowBack}>
                      <ArrowLeft />
                    </Button>
                  )}

                  <div className={styles.nameRow}>
                    {loading && <Skeleton width="200px" height="45px" />}
                    {!loading && (
                      <>
                        <div className={styles.title}>{name || activeRoute.name}</div>
                        {nameAdornment}
                      </>
                    )}
                  </div>
                </div>
                {(description || activeRoute?.description) && (
                  <div className={styles.nameRow}>
                    {loading && <Skeleton width="100px" height="15px" />}
                    {!loading && <span className={styles.subtitle}>{description || activeRoute?.description}</span>}
                  </div>
                )}
              </div>
            )}
            {children}
          </div>

          <div className={styles.spacer} />
          <Tooltip
            isOpen={isMenuOpen}
            isManualControl
            onCanShowChange={setMenuOpen}
            triggerElement={
              <Button appearance="none" onClick={() => setMenuOpen(true)}>
                <Avatar />
              </Button>
            }
            trigger="click"
            position="bottom-right"
            contentClassName={styles.triggerContent}
            triggerClassName={styles.menuTrigger}
          >
            <Menu closeMenu={() => setMenuOpen(false)} />
          </Tooltip>
        </div>
      </header>
    );
  },
);
