import { FC, useRef } from "react";
import {
  TableRow,
  TableCell,
  Menu,
  MenuButton,
  notification,
  TextEllipsis,
  getLinkPath,
  Switch,
  useQuery,
} from "@epcnetwork/core-ui-kit";
import { useSubmit } from "@hyper-fetch/react";
import { useNavigate } from "react-router-dom";

import { usePermissions } from "hooks";
import { deleteCompany, updateCompany } from "api";
import { OPTIZMO_CLIENTS_LIST_PAGE } from "constants/routes.constants";
import { DeleteResponse } from "api/api.types";
import { Accounts } from "assets";
import { Badge } from "components";
import { CompanyModel } from "models";

import styles from "./optizmo-company-item.module.scss";

type OptizmoCompanyItemProps = {
  index: number;
  company: CompanyModel;
  handleEdit: (company: CompanyModel) => void;
  onRemoveSuccess: (response: DeleteResponse) => void;
  onActivateSuccess: (company: CompanyModel) => void;
};

export const OptizmoCompanyItem: FC<OptizmoCompanyItemProps> = ({
  index,
  company,
  handleEdit,
  onRemoveSuccess,
  onActivateSuccess,
}) => {
  const navigate = useNavigate();
  const { query, stringify } = useQuery();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const switchRef = useRef<HTMLDivElement | null>(null);

  const [canUpdateAccount, canDeleteAccount] = usePermissions(["update", "delete"], "accounts");

  const remove = useSubmit(deleteCompany);
  remove.onSubmitSuccess(({ response }) => {
    onRemoveSuccess(response);
    notification.success("Optizmo company deleted", "Optizmo company successfully deleted");
  });
  remove.onSubmitError(() => {
    notification.error("Delete error", "Cannot delete this Iterable account.");
  });

  const update = useSubmit(updateCompany);
  update.onSubmitSuccess(({ response }) => {
    onActivateSuccess(response);
    notification.success(
      response.isActive ? "Optizmo company activated" : "Optizmo company deactivated",
      response.isActive ? "Optizmo company is now active." : "Optizmo company is deactivated.",
    );
  });
  update.onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the company");
  });

  const handleRemoveItem = () => {
    notification.confirm(
      "Do you want to delete this Optizmo company?",
      "This action is irreversible and will remove all Optizmo clients and offers assigned to this company!",
      {
        onOk: async () => {
          await remove.submit({ params: { companyId: company.id } });
        },
      },
    );
  };

  const handleEditItem = () => handleEdit(company);

  const handleRowClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const excludeElements = [menuRef.current, switchRef.current];

    const containsExcluded = excludeElements.some((element) => element?.contains(e.target as HTMLElement));
    const isExcluded = excludeElements.some((element) => element === e.target);
    if (!containsExcluded && !isExcluded) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const path = getLinkPath(OPTIZMO_CLIENTS_LIST_PAGE.path, { companyId: company.id });
      // set navigate state to allow going back to with the same query params
      navigate(path, { state: { queryStringified: stringify(query) } });
    }
  };

  const handleSwitchChange = async (value: string, checked: boolean) => {
    await update.submit({ data: { isActive: checked }, params: { companyId: company.id } });
  };

  return (
    <TableRow id={company.id} onRowClick={handleRowClick} className={styles.row}>
      <TableCell className={styles.name}>
        <Accounts style={{ marginRight: "8px" }} />
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {company.name}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <div className={styles.switch} ref={switchRef}>
          <Switch value="toggle" checked={company.isActive} disableError onChange={handleSwitchChange} />
        </div>
      </TableCell>

      <TableCell>
        <div ref={menuRef} data-testid={`optizmo-menu-button-${index}`}>
          <Menu portalClassName={`iterable-menu-${index}`}>
            {canUpdateAccount && (
              <MenuButton onClick={handleEditItem} icon="edit" data-testid={`iterable-edit-${index}`}>
                Edit
              </MenuButton>
            )}
            {canDeleteAccount && (
              <MenuButton
                appearance="delete"
                onClick={handleRemoveItem}
                icon="delete"
                data-testid={`iterable-delete-${index}`}
              >
                Delete
              </MenuButton>
            )}
            {!canUpdateAccount && !canDeleteAccount && <div style={{ padding: "10px" }}>No Actions available</div>}
          </Menu>
        </div>
      </TableCell>
    </TableRow>
  );
};
