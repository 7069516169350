import React, { useState } from "react";
import { useSelector } from "react-redux";

import { UserAvatar } from "assets";
import { RootState } from "store";

import styles from "./avatar.module.scss";

export const Avatar: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.auth);

  const [avatarLoadError, setAvatarLoadError] = useState<boolean>(false);

  const hasAvatar = Boolean(user?.pictureUrl);

  if (hasAvatar && !avatarLoadError) {
    return (
      <img
        src={user?.pictureUrl || ""}
        alt="avatar"
        className={styles.image}
        onError={() => setAvatarLoadError(true)}
      />
    );
  }

  return (
    <div className={styles.container}>
      <UserAvatar />
    </div>
  );
};
