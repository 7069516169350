import React, { FC } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form } from "formik";
import { Button, MessageField, FormField, notification, Modal } from "@epcnetwork/core-ui-kit";
import { useParams } from "react-router-dom";

import { ClientData, createClient } from "api";
import { CreateOptizmoClientFormData, CreateOptizmoClientProps } from "./create-optizmo-client.types";
import { validationSchema, initialValues } from "./create-optizmo-client.constants";

import styles from "../create-optizmo.module.scss";

export const CreateOptizmoClient: FC<CreateOptizmoClientProps> = ({ isOpen, close, onCreateSuccess }) => {
  const { companyId } = useParams<{ companyId: string }>();

  const { submit, error, onSubmitSuccess, onSubmitError } = useSubmit(createClient);
  onSubmitSuccess(({ response }) => {
    onCreateSuccess(response);
    close();
    notification.success("Optizmo client created!", "Optizmo client has been successfully created.");
  });
  onSubmitError(() => {
    notification.error("Create error!", "Error occurred while creating a new Optizmo client.");
  });

  const handleClose = () => {
    close();
  };

  const handleSubmit = async (values: CreateOptizmoClientFormData) => {
    if (companyId) {
      const data: ClientData = { ...values, companyId: +companyId };
      await submit({ data });
    }
  };

  return (
    <Modal isOpen={isOpen} setClose={handleClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ isValid }) => {
          return (
            <Form>
              <div className={styles.title}>Add Optizmo client</div>
              <MessageField message={error?.message || ""} />

              <FormField type="text" name="name" label="Client name" disableFloatingLabel required />

              <div className={styles.buttons}>
                <Button appearance="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!isValid}>
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
