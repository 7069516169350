import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Optizmo companies page</h2>
    <p>
      The Optizmo companies list page is your central hub for managing all companies in the system, displaying each
      company&apos;s essential information and activation status.
    </p>
  </div>
);

const secondStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Create Optizmo company button</h2>
    <p> Click the Add Company button to seamlessly add a new company to the list.</p>
    <p>You&apos;ll be prompted to input a company name and a unique token which is optional value.</p>
  </div>
);

const thirdStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Companies list</h2>
    <p>
      The main list showcases each company’s name, optional token, and active status, providing a clear snapshot of all
      entities.
    </p>
    <p>Use the active switch to enable or disable a company with a single click</p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".optizmo-navbar", on: "bottom-start" },
    permission: ["read", "integrations"],
  },
  {
    text: renderToString(secondStep),
    attachTo: { element: ".add-optizmo-company", on: "top" },
    permission: ["create", "integrations"],
  },
  {
    text: renderToString(thirdStep),
    attachTo: { element: ".table", on: "top" },
    permission: ["read", "integrations"],
  },
];
