import React from "react";

import { Container } from "components";
import { Mfa } from "./mfa/mfa";

import styles from "./settings.module.scss";

export const SettingsPage: React.FC = () => {
  return (
    <Container className={styles.container}>
      <Mfa />
    </Container>
  );
};
