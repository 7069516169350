import React, { useMemo, useState } from "react";
import { useFetch, useSubmit } from "@hyper-fetch/react";
import { Button, notification, Search, Switch, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";
import { useParams } from "react-router-dom";
import cn from "classnames";

import {
  BreadcrumbsOption,
  Container,
  DeleteMultipleButton,
  TableError,
  TableNoContent,
  SelectAllButton,
} from "components";
import { Navbar } from "components/layout/navbar/navbar";
import { useItemManagement, usePermissions, useSidebar, useTablePagination, useTour } from "hooks";
import { filtersOptions, optizmoCompaniesColumns, TABLE_NAME } from "./optizmo-clients.constants";
import { OptizmoClientItem } from "./table-item/optizmo-client-item";
import { CreateOptizmoClient, UpdateOptizmoClient } from "pages/integrations/optizmo";
import { OPTIZMO_COMPANIES_LIST_PAGE } from "constants/routes.constants";
import { deleteClients, getClients, getCompany, updateCompany } from "api";
import { ClientModel } from "models";
import { addItem, getInitialStorageFilters, removeItem, updateItem } from "utils";
import { DeleteResponse } from "api/api.types";
import { BasePaginationQuery, BaseSearchQuery } from "types";
import { steps } from "./optizmo-clients.tour";
import { Plus } from "assets";

import styles from "./optizmo-clients.module.scss";

export const OptizmoClientsPage: React.FC = () => {
  useTour({
    name: "optizmo-clients",
    steps,
  });

  const { isMenuCollapsed, setMenuCollapsed } = useSidebar();
  const { companyId } = useParams<{ companyId: string }>();
  const { create } = useItemManagement<ClientModel>();
  const { update } = useItemManagement<ClientModel>();
  const { query, searchValue, setSearch } = useFilters<BaseSearchQuery & BasePaginationQuery>(
    getInitialStorageFilters<BaseSearchQuery & BasePaginationQuery>("optizmo-clients", filtersOptions),
  );

  const [canCreateAccount] = usePermissions("create", "accounts");
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const companyDetails = useFetch(getCompany.setParams({ companyId: companyId || "" }), {
    disabled: !companyId,
  });

  const { data, loading, refetch, error, setData } = useFetch(
    getClients.setQueryParams({ ...query, search: query.search, companyId: companyId || "" }),
  );

  const pagination = usePagination({ listPayload: data });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  const edit = useSubmit(updateCompany);
  edit.onSubmitSuccess(({ response }) => {
    companyDetails.setData(response);
    notification.success(
      response.isActive ? "Optizmo company activated" : "Optizmo company deactivated",
      response.isActive ? "Optizmo company is now active." : "Optizmo company is deactivated.",
    );
  });
  edit.onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the company.");
  });

  const { submit, onSubmitSuccess, onSubmitError } = useSubmit(deleteClients);
  onSubmitSuccess(() => {
    notification.success("Optizmo clients deleted", "Optizmo clients successfully deleted");
    refetch();
    setSelectedRows([]);
  });
  onSubmitError(() => {
    notification.error("Delete error", "Cannot delete these Optizmo clients.");
  });

  const handleEdit = (client: ClientModel) => {
    update.setItem(client);
  };

  const handleCreateAccountSuccess = (client: ClientModel) => {
    if (data) addItem(client, data, setData);
  };

  const handleUpdateAccountSuccess = (client: ClientModel) => {
    if (data) updateItem(client, data, setData);
  };

  const handleRemoveSuccess = (response: DeleteResponse) => {
    if (data) removeItem(response, data, setData);
  };

  const handleItemSelect = (id: number, checked: boolean) => {
    const rows = checked ? [id, ...selectedRows] : selectedRows.filter((row) => row !== id);
    setSelectedRows(rows);
  };

  const handleMultipleDelete = () => {
    notification.confirm(
      `Do you want to delete ${selectedRows.length} Optizmo client${selectedRows.length > 1 ? "s" : ""}?`,
      "This action is irreversible.",
      {
        onOk: async () => {
          await submit({ data: { ids: selectedRows } });
        },
      },
    );
  };

  const handleSwitchChange = async (value: string, checked: boolean) => {
    if (companyDetails.data)
      await edit.submit({ data: { isActive: checked }, params: { companyId: companyDetails.data.id } });
  };

  const allSelected = useMemo(() => {
    if (!data?.data) return false;

    return selectedRows.length === data.data.length;
  }, [selectedRows, data]);

  const handleSelectAll = () => {
    if (data?.data) {
      const ids = data.data.map((client) => client.id);
      setSelectedRows(selectedRows.length === ids.length ? [] : ids);
    }
  };

  const toggleSidebar = () => setMenuCollapsed(!isMenuCollapsed);

  const breadcrumbs: BreadcrumbsOption[] = [
    { name: "Optizmo companies", path: OPTIZMO_COMPANIES_LIST_PAGE.path },
    companyDetails.data?.name || "",
  ];

  return (
    <>
      <Navbar
        isMenuCollapsed={isMenuCollapsed}
        toggleSidebar={toggleSidebar}
        name={`${companyDetails.data?.name || ""} clients`}
        loading={companyDetails.loading}
        description="List of all Optizmo clients. Click on item to see all offers added to this client."
        navbarClassName="optizmo-clients-navbar"
        backPage={OPTIZMO_COMPANIES_LIST_PAGE.path}
        breadcrumbs={breadcrumbs}
      >
        <div className={cn(styles.row, "active-switch")}>
          <div className={styles.switch}>
            <Switch
              value="toggle"
              checked={companyDetails.data?.isActive || false}
              disableError
              onChange={handleSwitchChange}
            />
          </div>
          {companyDetails.data?.isActive && (
            <div className={styles.notActiveText}>
              Optizmo company is currently active. All active clients will be included.
              <br />
              You can deactivate this account using the toggle switch on the left.
            </div>
          )}
          {!companyDetails.data?.isActive && (
            <div className={styles.notActiveText}>
              This Optizmo company is not currently active. None of these clients will be included for suppressions.
              <br />
              You can activate this account using the toggle switch on the left.
            </div>
          )}
        </div>
      </Navbar>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Search className={styles.search} searchValue={searchValue} setSearch={setSearch} />

            <div className={styles.row}>
              {selectedRows.length > 0 && (
                <DeleteMultipleButton
                  onClick={handleMultipleDelete}
                  text={`Delete selected clients (${selectedRows.length})`}
                  total={selectedRows.length}
                />
              )}

              <SelectAllButton onClick={handleSelectAll} allSelected={allSelected} />

              {canCreateAccount && (
                <Button onClick={create.open} className="add-optizmo-client" btnSize="small">
                  <Plus /> Add Optizmo client
                </Button>
              )}
            </div>
          </div>

          <Table
            entityName={TABLE_NAME}
            columns={optizmoCompaniesColumns}
            list={data?.data}
            error={error?.message}
            refresh={() => refetch()}
            loading={loading}
            resetColumnsOnMount={false}
            isTabTable
            customNoContent={<TableNoContent withBackground />}
            customError={<TableError description={error?.message} withBackground />}
            pagination={{
              ...pagination,
              elementsPerPage: currentElementsPerPage,
              onElementsPerPageChange: handlePerPageChange,
            }}
            row={(client, index) => (
              <OptizmoClientItem
                index={index}
                client={client}
                handleEdit={handleEdit}
                onRemoveSuccess={handleRemoveSuccess}
                onActivateSuccess={handleUpdateAccountSuccess}
                isSelected={selectedRows.includes(client.id)}
                onSelect={handleItemSelect}
                company={companyDetails.data}
              />
            )}
          />
          <CreateOptizmoClient
            isOpen={create.isOpen}
            close={create.close}
            onCreateSuccess={handleCreateAccountSuccess}
          />
          <UpdateOptizmoClient
            client={update.item}
            isOpen={update.isOpen}
            close={update.close}
            onUpdateSuccess={handleUpdateAccountSuccess}
          />
        </div>
      </Container>
    </>
  );
};
