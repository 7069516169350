import { useDispatch } from "react-redux";

import { setToken, setMfaTemporaryToken } from "store";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";

export const useMfa = () => {
  const dispatch = useDispatch();

  const setTokens = (userToken: string, userRefreshToken: string) => {
    dispatch(setToken(userToken));
    localStorage.setItem(STORAGE_FIELDS.token, userToken);
    localStorage.setItem(STORAGE_FIELDS.refreshToken, userRefreshToken);
  };

  const startMfa = (token: string) => {
    dispatch(setMfaTemporaryToken(token));
  };

  return { setTokens, startMfa };
};
