import React, { useState } from "react";

import { Container, TabOption, Tabs } from "components";
import { SuppressFromFile } from "./suppress-from-file/suppress-from-file";
import { SuppressManually } from "./suppress-manually/suppress-manually";

import styles from "./create-suppression.module.scss";

export type Method = "manual" | "file";

export const CreateSuppressionPage: React.FC = () => {
  const [method, setMethod] = useState<Method>("manual");

  const modeOptions: TabOption<Method>[] = [
    { value: "manual", label: "Manual", className: "manual-tab" },
    { value: "file", label: "File", className: "file-tab" },
  ];

  const handleRangeChange = (option: TabOption<Method>) => {
    setMethod(option.value);
  };

  return (
    <Container className={styles.wrapper}>
      <div className={styles.tabsWrapper}>
        <Tabs options={modeOptions} selected={method} onChange={handleRangeChange} className={styles.tabs} />
      </div>
      <div className={styles.container}>
        {method === "manual" && <SuppressManually handleFileClick={() => handleRangeChange(modeOptions[1])} />}
        {method === "file" && <SuppressFromFile />}
      </div>
    </Container>
  );
};
