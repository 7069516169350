import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Whitelist table</h2>
    <p>
      The Whitelist Table displays all users who are allowed to access the Heiser app. This includes both active users
      and those who have not yet signed in.
    </p>
    <p>From here, admins can manage the whitelist, ensuring only approved users are granted access to the system.</p>
  </div>
);

const secondStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Create new whitelist user</h2>
    <p>
      This button allows admins to manually add a new user to the whitelist. By entering the user&apos;s email address,
      admins can give them pre-approved access to the app, ensuring they can sign in when needed without additional
      authorization steps.
    </p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".table", on: "top" },
    permission: ["read", "users"],
  },
  {
    text: renderToString(secondStep),
    attachTo: { element: ".add-whitelist-item", on: "top" },
    permission: ["create", "users"],
  },
];
