import React from "react";
import { Search, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";
import { useFetch } from "@hyper-fetch/react";

import { TableError, TableNoContent } from "components";
import { UpdateUser } from "./update/update-user";
import { useItemManagement, useTablePagination, useTour } from "hooks";
import { UserModel } from "models";
import { getUsers, GetUsersQuery } from "api";
import { getInitialStorageFilters, updateItem } from "utils";
import { TABLE_NAME, initialFilters, usersListColumns } from "./users-list.constants";
import { UserTableItem } from "./table-item/user-table-item";
import { steps } from "./users-list.tour";

import styles from "./users-list.module.scss";

interface Props {
  whitelistClick: () => void;
}

export const UsersList: React.FC<Props> = ({ whitelistClick }) => {
  useTour({
    name: "users-list",
    steps: steps({ whitelistClick }),
  });

  const { update } = useItemManagement<UserModel>();
  const { query, searchValue, setSearch } = useFilters<GetUsersQuery>(
    getInitialStorageFilters<GetUsersQuery>(TABLE_NAME, initialFilters),
  );

  const { data, loading, refetch, error, setData } = useFetch(getUsers.setQueryParams(query));

  const pagination = usePagination({ listPayload: data });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  const handleEdit = (user: UserModel) => {
    update.setItem(user);
  };

  const handleUpdateSuccess = (user: UserModel) => {
    if (data) updateItem(user, data, setData);
  };

  return (
    <div>
      <div className={styles.head}>
        <Search searchValue={searchValue} setSearch={setSearch} />
      </div>

      <Table
        entityName="users-user-list"
        columns={usersListColumns}
        list={data?.data}
        error={error?.message}
        refresh={refetch}
        loading={loading}
        resetColumnsOnMount={false}
        customNoContent={<TableNoContent withBackground />}
        customError={<TableError description={error?.message} withBackground />}
        pagination={{
          ...pagination,
          elementsPerPage: currentElementsPerPage,
          onElementsPerPageChange: handlePerPageChange,
        }}
        row={(user, index) => (
          <UserTableItem index={index} user={user} handleEdit={handleEdit} handleUpdateSuccess={handleUpdateSuccess} />
        )}
      />
      <UpdateUser
        user={update.item}
        isOpen={update.isOpen}
        close={update.close}
        onUpdateSuccess={handleUpdateSuccess}
      />
    </div>
  );
};
