import React from "react";
import { Tooltip } from "@epcnetwork/core-ui-kit";
import { TrashIcon } from "lucide-react";

import { Badge } from "components/ui/badge";

import styles from "./delete-multiple-button.module.scss";

type DeleteMultipleButtonProps = {
  onClick: () => void;
  text: string;
  total: number;
};

export const DeleteMultipleButton: React.FC<DeleteMultipleButtonProps> = ({ onClick, text, total }) => {
  return (
    <Tooltip
      position="top"
      trigger="hover"
      triggerElement={
        <div>
          <button type="button" onClick={onClick} className={styles.deleteButton}>
            <TrashIcon width={14} height={14} />
            Delete
            <Badge variant="error">{total}</Badge>
          </button>
        </div>
      }
    >
      {text}
    </Tooltip>
  );
};
