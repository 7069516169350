import { FC, useState } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form, FormikProps } from "formik";
import {
  Button,
  MessageField,
  FormButtons,
  FormField,
  notification,
  Modal,
  Label,
  Switch,
} from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { updateBusinessUnit, UpdateBusinessUnitData } from "api";
import { UpdateBusinessUnitProps } from "./update-salesforce-business-unit.types";
import { validationSchema, initialValues } from "./update-salesforce-business-unit.constants";

import styles from "../update-salesforce.module.scss";

export const UpdateSalesforceBusinessUnit: FC<UpdateBusinessUnitProps> = ({
  isOpen,
  businessUnit,
  close,
  onUpdateSuccess,
}) => {
  const [clientSecretEnabled, setClientSecretEnabled] = useState<boolean>(false);

  const { submit, error, onSubmitSuccess, onSubmitError } = useSubmit(updateBusinessUnit);
  onSubmitSuccess(({ response }) => {
    onUpdateSuccess(response);
    close();
    notification.success("Business Unit updated!", "Business Unit has been successfully updated.");
  });
  onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the Business Unit.");
  });

  useDidUpdate(() => {
    setClientSecretEnabled(false);
  }, [isOpen]);

  const handleSwitchToggle =
    (formikProps: FormikProps<Partial<UpdateBusinessUnitData>>) => (value: string, checked: boolean) => {
      setClientSecretEnabled(checked);
      if (!checked) {
        formikProps.setFieldValue("clientSecret", "");
        formikProps.setFieldTouched("clientSecret", false, false);
      }
    };

  const handleSubmit = async (values: UpdateBusinessUnitData) => {
    if (businessUnit) {
      const data = { ...values };
      if (!clientSecretEnabled) delete data.clientSecret;

      await submit({
        data,
        params: { businessUnitId: businessUnit.id },
      });
    }
  };

  return (
    <Modal isOpen={isOpen} setClose={close}>
      <Formik
        initialValues={initialValues(businessUnit)}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(clientSecretEnabled)}
        enableReinitialize
      >
        {(props) => (
          <Form>
            <div className={styles.title}>Update {businessUnit?.name} business unit</div>
            <p className={styles.subtitle}>
              If the switch is checked, a new Client Secret will be required. When the switch is not checked, the Client
              Secret that was added earlier will be used.
            </p>
            <MessageField message={error?.message || ""} />
            <div className={styles.row}>
              <FormField type="text" name="name" label="Account name" disableFloatingLabel required />
              <FormField type="text" name="authUrl" label="Auth URL" disableFloatingLabel required />
            </div>
            <div className={styles.row}>
              <div>
                <div className={styles.alignLabelRow}>
                  <Label text="Client ID" isInputLabel />
                </div>
                <FormField type="text" name="clientId" disableFloatingLabel />
              </div>
              <div>
                <div className={styles.labelRow}>
                  <Label text="Client secret" isInputLabel />
                  <Switch
                    value="test"
                    disableError
                    checked={clientSecretEnabled}
                    onChange={handleSwitchToggle(props)}
                  />
                </div>
                <FormField
                  type="text"
                  name="clientSecret"
                  placeholder={clientSecretEnabled ? "Provide a new Client Secret" : "Use the same Client Secret"}
                  disableFloatingLabel
                  disabled={!clientSecretEnabled}
                />
              </div>
            </div>
            <FormField type="text" name="accountId" label="Account ID" disableFloatingLabel />

            <FormButtons className={styles.buttons}>
              <Button appearance="secondary" onClick={close}>
                Cancel
              </Button>
              <Button type="submit">Submit</Button>
            </FormButtons>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
