import React from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { useSubmit } from "@hyper-fetch/react";
import { Loader } from "@epcnetwork/core-ui-kit";
import { useAbility } from "@casl/react";

import { getMe } from "api";
import { routes } from "config/routes.config";
import { RootState, setGlobalLoading, setToken, setUser } from "store";
import { DASHBOARD_PAGE, LOGIN_PAGE } from "constants/routes.constants";
import { STORAGE_FIELDS } from "constants/storage-fields.constants";
import { updatePermissions } from "permissions";
import { PermissionAbilityCtx } from "hooks";

import styles from "./app.module.scss";

export const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const ability = useAbility(PermissionAbilityCtx);

  const { token, user } = useSelector((state: RootState) => state.auth);
  const { globalLoading } = useSelector((state: RootState) => state.app);

  const resetAndRedirect = () => {
    dispatch(setToken(null));
    localStorage.removeItem(STORAGE_FIELDS.token);
    localStorage.removeItem(STORAGE_FIELDS.refreshToken);

    navigate(LOGIN_PAGE.path);
  };

  const { submit, onSubmitSuccess, onSubmitError, onSubmitFinished } = useSubmit(getMe);
  onSubmitSuccess(async ({ response }) => {
    dispatch(setUser(response));
    updatePermissions(ability, response.isAdmin);
  });
  onSubmitError(() => {
    resetAndRedirect();
  });
  onSubmitFinished(() => {
    dispatch(setGlobalLoading(false));
  });

  useDidUpdate(
    () => {
      if (token && user) {
        if (location.pathname === LOGIN_PAGE.path) navigate(DASHBOARD_PAGE.path);
      } else if (token && !user) {
        submit();
      } else {
        resetAndRedirect();
        dispatch(setGlobalLoading(false));
      }
    },
    [token, user],
    true,
  );

  const appRoutes = useRoutes(routes);

  if (globalLoading) {
    return (
      <div className={styles.loader}>
        <Loader type="puff-loader" size={100} />
      </div>
    );
  }

  return <div>{appRoutes}</div>;
};
