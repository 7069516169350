import { FC } from "react";
import {
  TableRow,
  TableCell,
  Menu,
  MenuButton,
  notification,
  TextEllipsis,
  useWindowSize,
  formatDate,
} from "@epcnetwork/core-ui-kit";
import { useSubmit } from "@hyper-fetch/react";
import { useSelector } from "react-redux";

import { UserModel } from "models";
import { usePermissions } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import { updateUser } from "api";
import { Calendar, Clock } from "assets";
import { Badge } from "components";
import { RootState } from "store";

import styles from "./user-table-item.module.scss";

type UsersListTableRowProps = {
  index: number;
  user: UserModel;
  handleEdit: (user: UserModel) => void;
  handleUpdateSuccess: (user: UserModel) => void;
};

const UserTableItem: FC<UsersListTableRowProps> = ({ index, user, handleEdit, handleUpdateSuccess }) => {
  const { width } = useWindowSize();
  const { user: currentUser } = useSelector((state: RootState) => state.auth);
  const { id, email, isAdmin, createdAt } = user;

  const [canCreateUser, canDeleteUser] = usePermissions(["create", "delete"], "users");

  const { submit, onSubmitSuccess, onSubmitError } = useSubmit(updateUser);
  onSubmitSuccess(({ response }) => {
    handleUpdateSuccess(response);
    notification.success(
      response.isActive ? "User activated" : "User deactivated",
      response.isActive ? "User successfully activated" : "User successfully deactivated",
    );
  });
  onSubmitError(({ request }) => {
    notification.error(
      request.data?.isActive ? "Activate error" : "Deactivate error",
      request.data?.isActive ? "Cannot activate this user." : "Cannot deactivate this user.",
    );
  });

  const handleUserActive = () => {
    notification.confirm(
      user.isActive ? "Do you want to deactivate this user?" : "Do you want to activate this user?",
      "You can always activate or deactivate users later.",
      {
        onOk: async () => {
          await submit({ data: { isActive: !user.isActive }, params: { userId: id } });
        },
      },
    );
  };

  const handleEditItem = () => handleEdit(user);

  const isMobile = width < MAX_MOBILE_WIDTH;

  return (
    <TableRow id={index}>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {email}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          <Badge>
            <span>{isAdmin ? "Admin" : "User"}</span>
          </Badge>
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.cellRow}>
        <div className={styles.row}>
          <Calendar />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAt, "EEE, MMM dd, yyyy")}
          </TextEllipsis>
        </div>
        <div className={styles.row}>
          <Clock />
          <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
            {formatDate(createdAt, "HH:mm:ss")}
          </TextEllipsis>
        </div>
      </TableCell>
      <TableCell>
        <Badge variant={user.isActive ? "success" : "error"}>{user.isActive ? "Active" : "Inactive"}</Badge>
      </TableCell>
      <TableCell>
        {!isMobile && (
          <Menu>
            {canCreateUser && (
              <MenuButton onClick={handleEditItem} disabled={id === currentUser?.id}>
                Edit
              </MenuButton>
            )}
            {canDeleteUser && (
              <MenuButton appearance="default" onClick={handleUserActive} disabled={id === currentUser?.id}>
                {user.isActive ? "Deactivate" : "Activate"}
              </MenuButton>
            )}
            {!canCreateUser && !canDeleteUser && <div style={{ padding: "10px" }}>No Actions available</div>}
          </Menu>
        )}
      </TableCell>
    </TableRow>
  );
};

export { UserTableItem };
