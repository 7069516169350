import React, { useState } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { CredentialResponse, GoogleLogin as GoogleLoginComponent } from "@react-oauth/google";
import { Button, Modal, notification } from "@epcnetwork/core-ui-kit";

import { devLogin, login } from "api";
import { Loader } from "components";
import { appEnvironment } from "config/environment.config";
import { useMfa } from "hooks";
import { CrossCircle } from "assets";

import styles from "../login.module.scss";

export const GoogleLogin: React.FC = () => {
  const { startMfa, setTokens } = useMfa();

  const [errorOpen, setErrorOpen] = useState<boolean>(false);

  const { submit, submitting, onSubmitSuccess, onSubmitError } = useSubmit(login);
  onSubmitSuccess(async ({ response }) => {
    if (response.isMfaEnabled) startMfa(response.token);
    if (!response.isMfaEnabled) setTokens(response.token, response.refreshToken);
  });
  onSubmitError(() => {
    setErrorOpen(true);
  });

  const devSignIn = useSubmit(devLogin);
  devSignIn.onSubmitSuccess(async ({ response }) => {
    if (response.isMfaEnabled) startMfa(response.token);
    if (!response.isMfaEnabled) setTokens(response.token, response.refreshToken);
  });
  devSignIn.onSubmitError(({ response }) => {
    notification.error("Dev sign in error", response?.message || "Error while trying to sign in.");
  });

  const executeGoogleLogin = async (credentialResponse: CredentialResponse) => {
    if (credentialResponse && credentialResponse?.credential) {
      await submit({
        data: { idToken: credentialResponse.credential },
      });
    }
  };

  const handleDevLogin = async () => {
    if (appEnvironment.nodeEnv) {
      await devSignIn.submit({ data: { email: appEnvironment.devSignInEmail } });
    }
  };

  return (
    <div>
      {appEnvironment.nodeEnv === "development" && <Button onClick={handleDevLogin}>Dev sign in</Button>}
      <GoogleLoginComponent onSuccess={executeGoogleLogin} size="large" />

      <div className={styles.loader}>{submitting && <Loader isAbsolute={true} />}</div>

      <Modal isOpen={errorOpen} setClose={() => setErrorOpen(false)} contentClassName={styles.errorModal}>
        <div className={styles.row}>
          <CrossCircle className={styles.icon} />
          <p>No access</p>
        </div>
        <h1>It looks like you don’t have access yet.</h1>
        <p>Please contact your marketing manager to request access.</p>

        <div className={styles.buttonWrapper}>
          <Button appearance="secondary" onClick={() => setErrorOpen(false)}>
            Ok
          </Button>
        </div>
      </Modal>
    </div>
  );
};
