import React from "react";
import { Modal, notification } from "@epcnetwork/core-ui-kit";
import QRCode from "react-qr-code";
import { useSubmit } from "@hyper-fetch/react";

import { useMfa } from "hooks";
import { OtpInput } from "components";
import { QrModalProps } from "./qr-modal.types";
import { enableMfa } from "api";

import styles from "./qr-modal.module.scss";

export const QrModal: React.FC<QrModalProps> = ({ isOpen, handleClose, qr, onSuccess }) => {
  const { setTokens } = useMfa();

  const { submit, onSubmitSuccess, onSubmitError, submitting, error } = useSubmit(enableMfa);
  onSubmitSuccess(({ response }) => {
    notification.success("Multi-factor authentication enabled", "You enabled virtual MFA for your account.");
    onSuccess?.();
    handleClose();
    setTokens(response.token, response.refreshToken);
  });
  onSubmitError(() => {
    notification.error("Multi-factor authentication error", "Could not enable virtual MFA for your account.");
  });

  const handleSubmit = async (value: string) => {
    await submit({ data: { code: value } });
  };

  return (
    <Modal isOpen={isOpen} setClose={handleClose} size="small">
      <div className={styles.wrapper}>
        <h4 className={styles.title}>
          Scan this QR code in the authenticator app
          <br />
          (Google Authenticator or Microsoft Authenticator)
        </h4>
        <QRCode value={qr} className={styles.qrCode} />
        <div className={styles.qrLink}>
          <p>
            Click
            <a href={qr} className={styles.link}>
              here
            </a>
            to start using the Authenticator app
          </p>
        </div>

        <div className={styles.qrForm}>
          <p className={styles.qrCodeLabel}>Enter code from the authenticator app</p>
          <div>
            <OtpInput onSubmit={handleSubmit} submitting={submitting} error={error} />
          </div>
        </div>
      </div>
    </Modal>
  );
};
