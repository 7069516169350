import React from "react";
import { Button, Search, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";
import { useFetch } from "@hyper-fetch/react";

import { TableError, TableNoContent } from "components";
import { useItemManagement, usePermissions, useTablePagination, useTour } from "hooks";
import { WhitelistItemModel } from "models";
import { GetUsersQuery, getWhitelistItems, GetWhitelistItemsQuery } from "api";
import { addItem, getInitialStorageFilters, removeItem } from "utils";
import { initialFilters, TABLE_NAME, whitelistUsersColumns } from "./whitelist.constants";
import { CreateWhitelistItem } from "./create/create-whitelist-item";
import { WhitelistTableItem } from "./table-item/whitelist-table-item";
import { DeleteResponse } from "api/api.types";
import { Plus } from "assets";
import { steps } from "./whitelist.tour";

import styles from "./whitelist.module.scss";

export const Whitelist: React.FC = () => {
  useTour({
    name: "whitelist-list",
    steps,
  });

  const [canCreateWhitelistItem] = usePermissions("create", "users");

  const { create } = useItemManagement();
  const { query, searchValue, setSearch } = useFilters<GetWhitelistItemsQuery>(
    getInitialStorageFilters<GetUsersQuery>(TABLE_NAME, initialFilters),
  );

  const { data, loading, refetch, error, setData } = useFetch(getWhitelistItems.setQueryParams(query));

  const pagination = usePagination({ listPayload: data });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  const onCreateSuccess = (item: WhitelistItemModel) => {
    if (data) addItem<WhitelistItemModel>(item, data, setData);
  };

  const handleDeleteSuccess = (item: DeleteResponse) => {
    if (data) removeItem(item, data, setData);
  };

  return (
    <div>
      <div className={styles.head}>
        <Search searchValue={searchValue} setSearch={setSearch} />
        {canCreateWhitelistItem && (
          <Button onClick={create.open} className="add-whitelist-item">
            <Plus />
            Add whitelist item
          </Button>
        )}
      </div>

      <Table
        entityName={TABLE_NAME}
        columns={whitelistUsersColumns}
        list={data?.data}
        error={error?.message}
        refresh={refetch}
        loading={loading}
        resetColumnsOnMount={false}
        customNoContent={<TableNoContent withBackground />}
        customError={<TableError description={error?.message} withBackground />}
        pagination={{
          ...pagination,
          elementsPerPage: currentElementsPerPage,
          onElementsPerPageChange: handlePerPageChange,
        }}
        row={(item, index) => (
          <WhitelistTableItem index={index} item={item} handleDeleteSuccess={handleDeleteSuccess} />
        )}
      />
      <CreateWhitelistItem isOpen={create.isOpen} close={create.close} onCreateSuccess={onCreateSuccess} />
    </div>
  );
};
