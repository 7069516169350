import { useNavigate } from "react-router-dom";
import { FC } from "react";

import { DASHBOARD_PAGE } from "constants/routes.constants";

import { HeiserLogo } from "assets/icons";

import styles from "components/layout/sidebar/sidebar.module.scss";

const Logo: FC = () => {
  const navigate = useNavigate();

  const handleRedirectHome = () => {
    navigate(DASHBOARD_PAGE.path);
  };

  return (
    <div className={styles.logo} onClick={handleRedirectHome}>
      <HeiserLogo className={styles.heiserLogo} />
      Heiser
    </div>
  );
};

export { Logo };
