import React from "react";
import { Modal } from "@epcnetwork/core-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";

import { RootState, setChangelogOpen } from "store";
import { changelogStringExample } from "data/changelog-example";

export const Changelog: React.FC = () => {
  const dispatch = useDispatch();

  const { changelogOpen } = useSelector((state: RootState) => state.app);

  return (
    <Modal isOpen={changelogOpen} setClose={() => dispatch(setChangelogOpen(false))}>
      <ReactMarkdown>{changelogStringExample}</ReactMarkdown>
    </Modal>
  );
};
