import React from "react";
import { MongoAbility } from "@casl/ability";
import { ThemeProvider, NotificationProvider, FileQueueProvider } from "@epcnetwork/core-ui-kit";
import { useAbility } from "@casl/react";
import { BrowserRouter } from "react-router-dom";
import { EnhancedStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useDidMount } from "@better-hooks/lifecycle";

import { RootState, store } from "store";
import { lightTheme } from "constants/themes.constants";
import { PermissionAbilityCtx, useTracking } from "hooks";
import { appEnvironment } from "config/environment.config";
import { Changelog } from "components/layout";

type Props = {
  children: React.ReactNode;
  testStore?: EnhancedStore<Partial<RootState>>;
  testAbility?: MongoAbility;
};

export const Providers: React.FC<Props> = ({ children, testStore }: Props) => {
  const ability = useAbility(PermissionAbilityCtx);

  const { init } = useTracking();

  useDidMount(() => {
    init();
  });

  return (
    <Provider store={testStore || store}>
      <PermissionAbilityCtx.Provider value={ability}>
        <BrowserRouter>
          <ThemeProvider themes={[lightTheme]} initialTheme={lightTheme}>
            <GoogleOAuthProvider clientId={appEnvironment.googleClientId}>
              <FileQueueProvider>
                <NotificationProvider>
                  {children}
                  <Changelog />
                </NotificationProvider>
              </FileQueueProvider>
            </GoogleOAuthProvider>
          </ThemeProvider>
        </BrowserRouter>
      </PermissionAbilityCtx.Provider>
    </Provider>
  );
};
