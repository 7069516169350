import React from "react";
import { Tooltip } from "@epcnetwork/core-ui-kit";
import { List, ListChecks } from "lucide-react";
import cn from "classnames";

import styles from "./select-all-button.module.scss";

type SelectAllButtonProps = {
  onClick: () => void;
  allSelected: boolean;
};

export const SelectAllButton: React.FC<SelectAllButtonProps> = ({ onClick, allSelected }) => {
  return (
    <Tooltip
      position="top"
      trigger="hover"
      triggerElement={
        <div>
          <button
            type="button"
            onClick={onClick}
            className={cn(styles.selectButton, { [styles.selectedButton]: allSelected })}
          >
            {allSelected ? <ListChecks width={14} height={14} /> : <List width={14} height={14} />}
            <span>{allSelected ? "All selected" : "Select all"}</span>
          </button>
        </div>
      }
    >
      {allSelected ? "Unselect all" : "Select all"}
    </Tooltip>
  );
};
