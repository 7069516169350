import { FC, useRef } from "react";
import {
  TableRow,
  TableCell,
  Menu,
  MenuButton,
  notification,
  TextEllipsis,
  useWindowSize,
  Checkbox,
  Switch,
  Nullable,
} from "@epcnetwork/core-ui-kit";
import { useSubmit } from "@hyper-fetch/react";

import { BusinessUnitModel, DataExtensionModel } from "models";
import { usePermissions } from "hooks";
import { MAX_MOBILE_WIDTH } from "constants/screen.constants";
import { deleteDataExtension, updateDataExtension } from "api";
import { DeleteResponse } from "api/api.types";

import styles from "./data-extension-item.module.scss";

type UsersListTableRowProps = {
  index: number;
  dataExtension: DataExtensionModel;
  handleEdit: (dataExtension: DataExtensionModel) => void;
  onRemoveSuccess: (response: DeleteResponse[]) => void;
  isSelected: boolean;
  businessUnit: Nullable<BusinessUnitModel>;
  onSelect: (id: number, checked: boolean) => void;
  onActivateSuccess: (dataExtension: DataExtensionModel) => void;
};

export const DataExtensionItem: FC<UsersListTableRowProps> = ({
  index,
  dataExtension,
  handleEdit,
  businessUnit,
  onRemoveSuccess,
  isSelected,
  onSelect,
  onActivateSuccess,
}) => {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const switchRef = useRef<HTMLDivElement | null>(null);

  const { width } = useWindowSize();
  const { id, name } = dataExtension;

  const [canUpdateIntegrations, canDeleteIntegrations] = usePermissions(["update", "delete"], "integrations");

  const remove = useSubmit(deleteDataExtension);
  remove.onSubmitSuccess(({ response }) => {
    onRemoveSuccess(response);
    notification.success("Data extension deleted", "Data extension successfully deleted");
  });
  remove.onSubmitError(() => {
    notification.error("Delete error", "Cannot delete this Data Extension.");
  });

  const update = useSubmit(updateDataExtension);
  update.onSubmitSuccess(({ response }) => {
    onActivateSuccess(response);
    notification.success(
      response.isActive ? "Data Extension activated" : "Data Extension deactivated",
      response.isActive ? "Data Extension is now active." : "Data Extension is deactivated.",
    );
  });
  update.onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the Data Extension.");
  });

  const handleRemoveItem = () => {
    notification.confirm("Do you want to delete this Data Extension?", "This action is irreversible.", {
      onOk: async () => {
        await remove.submit({ data: { ids: [id] } });
      },
    });
  };

  const handleEditItem = () => handleEdit(dataExtension);

  const handleCheckboxClick = (value: string, isChecked: boolean) => {
    onSelect(id, isChecked);
  };

  const handleRowClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const excludeElements = [menuRef.current, switchRef.current];

    const containsExcluded = excludeElements.some((element) => element?.contains(e.target as HTMLElement));
    const isExcluded = excludeElements.some((element) => element === e.target);
    if (!containsExcluded && !isExcluded) {
      onSelect(id, !isSelected);
    }
  };

  const handleSwitchChange = async (value: string, checked: boolean) => {
    await update.submit({ data: { isActive: checked }, params: { dataExtensionId: dataExtension.id } });
  };

  const isMobile = width < MAX_MOBILE_WIDTH;

  return (
    <TableRow id={index} onRowClick={handleRowClick}>
      <TableCell className={styles.firstCell}>
        <div className={styles.checkboxCell}>
          <Checkbox
            value={id}
            onChange={handleCheckboxClick}
            onClick={(e) => e.stopPropagation()}
            className={styles.checkbox}
            checked={isSelected}
            name={`project-${id}`}
            inputSize="big"
            disableError
            showBox
          />
        </div>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" lines={2}>
          {name}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <div className={styles.switch} ref={switchRef}>
          <Switch
            value="toggle"
            checked={dataExtension.isActive}
            disableError
            disabled={!businessUnit?.isActive}
            onChange={handleSwitchChange}
          />
        </div>
      </TableCell>

      {(canUpdateIntegrations || canDeleteIntegrations) && (
        <TableCell>
          <div ref={menuRef} data-testid={`salesforce-data-extension-menu-button-${index}`}>
            {!isMobile && (
              <Menu portalClassName={`salesforce-data-extension-menu-${index}`}>
                {canUpdateIntegrations && (
                  <MenuButton
                    onClick={handleEditItem}
                    icon="edit"
                    data-testid={`salesforce-data-extension-edit-${index}`}
                  >
                    Edit
                  </MenuButton>
                )}
                {canDeleteIntegrations && (
                  <MenuButton
                    appearance="delete"
                    onClick={handleRemoveItem}
                    icon="delete"
                    data-testid={`salesforce-data-extension-delete-${index}`}
                  >
                    Delete
                  </MenuButton>
                )}
                {/*{!canUpdateIntegrations && !canDeleteIntegrations && (*/}
                {/*  <div style={{ padding: "10px" }}>No Actions available</div>*/}
                {/*)}*/}
              </Menu>
            )}
          </div>
        </TableCell>
      )}
    </TableRow>
  );
};
