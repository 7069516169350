import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>MD5 export page</h2>
    <p>
      The Export page is designed to help you create and manage export jobs, enabling you to export email lists as
      needed. Once configured, a job will be created and processed, with the exported file available for download later.
    </p>
  </div>
);

const secondStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Select an Optizmo offer</h2>
    <p>
      Start by selecting the offer you want to export by choosing a specific company and client, followed by the
      associated offer.
    </p>
  </div>
);

const thirdStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Select ESP and lists</h2>
    <p>
      After choosing the offer, select your preferred ESP (e.g., Iterable or Salesforce). Next, designate the “From” and
      “Target” lists, or choose “From Data Extension” and “Target Data Extension” to customize the source and
      destination within the ESP.
    </p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".md5-export-navbar", on: "bottom-start" },
    permission: ["create", "export"],
  },
  {
    text: renderToString(secondStep),
    attachTo: { element: ".offer-selector", on: "top" },
    permission: ["create", "export"],
  },
  {
    text: renderToString(thirdStep),
    attachTo: { element: ".esp-selector", on: "top" },
    permission: ["create", "export"],
  },
];
