import { combineReducers, configureStore } from "@reduxjs/toolkit";

import auth from "./reducers/auth";
import app from "./reducers/app";

const reducer = combineReducers({
  auth,
  app,
});

const store = configureStore({
  reducer,
});

export type RootState = ReturnType<typeof reducer>;

export * from "./reducers/auth";
export * from "./reducers/app";

export { store };
