import React, { useState } from "react";

type OptizmoContextType = {
  dataExtensionsError: boolean;
  setDataExtensionsError: (error: boolean) => void;
};

export const OptizmoExportContext = React.createContext<OptizmoContextType>({
  dataExtensionsError: false,
  setDataExtensionsError: () => null,
});

interface Props {
  children: React.ReactNode;
}

export const OptizmoExportContextProvider: React.FC<Props> = ({ children }) => {
  const [dataExtensionsError, setDataExtensionsError] = useState<boolean>(false);

  const value: OptizmoContextType = {
    dataExtensionsError,
    setDataExtensionsError,
  };

  return <OptizmoExportContext.Provider value={value}>{children}</OptizmoExportContext.Provider>;
};
