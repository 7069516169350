import { FC, useState } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form } from "formik";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { Button, MessageField, notification, Modal, Label, useFileQueue } from "@epcnetwork/core-ui-kit";

import {
  createIterableProjectWithFile,
  CreateIterableProjectWithUpload,
  CreateIterableProjectData,
  createProjects,
} from "api";
import { ProjectInputSelector } from "components";
import { CreateIterableProjectProps, ProjectFormData } from "./create-iterable-project.types";
import { validationSchema, initialValues, emptyProject } from "./create-iterable-project.constants";
import { FileUpload, ProjectsCreator } from "pages/integrations/form";
import { IntegrationFileItem } from "types";

import styles from "../create-iterable.module.scss";

export const CreateIterableProject: FC<CreateIterableProjectProps> = ({
  isOpen,
  close,
  refresh,
  account,
  onCreateSuccess,
}) => {
  const [projectsInputMode, setProjectsInputMode] = useState<"form" | "file">("form");

  const fileQueue = useFileQueue<IntegrationFileItem, CreateIterableProjectWithUpload>(
    "createProject",
    createIterableProjectWithFile,
  );

  const { submit, error, onSubmitSuccess, onSubmitError } = useSubmit(createProjects);
  onSubmitSuccess(({ response }) => {
    onCreateSuccess(response);
    close();
    notification.success("Iterable projects has been created!", "Projects has been successfully created.");
  });
  onSubmitError(() => {
    notification.error("Create error!", "Error occurred while creating the projects");
  });

  const handleClose = () => {
    close();
    fileQueue.removeFiles(fileQueue.files.map((file) => file.id));
    fileQueue.clearEntity();
  };

  useDidUpdate(() => {
    setProjectsInputMode("form");
  }, [isOpen]);

  useDidUpdate(() => {
    const haveError = fileQueue.files.some((file) => Boolean(file.error));

    if (fileQueue.isEntityFinished && !haveError) {
      refresh();
      handleClose();
      notification.success("Iterable projects created!", "Iterable projects have been successfully created.");
    }

    if (fileQueue.isEntityFinished && haveError) {
      notification.error("Create error!", "Error occurred while creating the project");
    }
  }, [fileQueue.isEntityFinished]);

  const handleSubmit = async (values: ProjectFormData) => {
    const formValues: CreateIterableProjectData = { ...values, accountId: account.id };

    if (fileQueue.files.length === 0) return submit({ data: formValues });

    const data = fileQueue.files.map((file) => {
      const formData = new FormData();

      const { hasHeaders, headers } = file.data;
      const nameIndex = headers.findIndex((header) => header === "name");
      const apiKeyIndex = headers.findIndex((header) => header === "apiKey");

      formData.append("form", JSON.stringify({ hasHeaders, nameIndex, apiKeyIndex, accountId: account.id }));
      formData.append("file", file.originalFile);

      return {
        id: file.id,
        data: formData,
      };
    });

    await fileQueue.submitOneByOne(data);
  };

  return (
    <Modal isOpen={isOpen} setClose={handleClose}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
        {({ values, setFieldValue, isValid }) => {
          const handleFormInputMode = async () => {
            setProjectsInputMode("form");
            fileQueue.removeFiles(fileQueue.files?.[0]?.id);
            await setFieldValue("projects", [...values.projects, emptyProject]);
          };

          const handleUploadMode = async () => {
            if (projectsInputMode === "file") return null;

            setProjectsInputMode("file");
            await setFieldValue("projects", []);
          };

          const isProjectsListEmpty = values.projects.length === 0;

          return (
            <Form>
              <div className={styles.title}>Create Iterable projects</div>
              <span>Add projects to {account.name} Iterable account</span>

              <MessageField message={error?.message || ""} />

              <div>
                <Label text="Projects" isInputLabel />
                <p className={styles.labelDescription}>Add new projects manually or upload a CSV file.</p>
              </div>

              <ProjectInputSelector
                isListEmpty={isProjectsListEmpty}
                handleFormInput={handleFormInputMode}
                handleUploadInput={handleUploadMode}
              />
              {projectsInputMode === "form" && <ProjectsCreator />}
              {projectsInputMode === "file" && <FileUpload fileQueue={fileQueue} integrationMode="iterable" />}

              <div className={styles.buttons}>
                <Button appearance="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!fileQueue.hasAllConfigured || !isValid}>
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
