import { object, string, Schema } from "yup";

import { UpdateClientData } from "api";
import { ClientModel } from "models";

export const validationSchema: Schema<UpdateClientData> = object().shape({
  name: string().required("Name is required"),
});

export const initialValues = (company: ClientModel | null): UpdateClientData => ({
  name: company?.name || "",
});
