import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Optizmo offers page</h2>
    <p>
      The Offers List page provides an organized view of all offers available in the system, along with each offer’s
      name and access key status.
    </p>
  </div>
);

const secondStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Create Optizmo offer button</h2>
    <p>
      To add a new offer, click the add offer button. Enter the offer name and designate an access key to control
      availability based on each company’s token.
    </p>
  </div>
);

const thirdStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Offers list</h2>
    <p>In the main list, each offer’s name and active state are shown.</p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".optizmo-offers-navbar", on: "bottom-start" },
    permission: ["read", "integrations"],
  },
  {
    text: renderToString(secondStep),
    attachTo: { element: ".add-optizmo-offer", on: "top" },
    permission: ["create", "integrations"],
  },
  {
    text: renderToString(thirdStep),
    attachTo: { element: ".table", on: "top" },
    permission: ["read", "integrations"],
  },
];
