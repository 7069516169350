import { FC } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form } from "formik";
import { Button, MessageField, FormField, notification, Modal, Label } from "@epcnetwork/core-ui-kit";

import { createBusinessUnit, CreateBusinessUnitData, CreateBusinessUnitFormData } from "api";
import { CreateSalesforceAccountProps } from "./create-salesforce-business-unit.types";
import { validationSchema, initialValues } from "./create-salesforce-business-unit.constants";
import { Hint } from "components";

import styles from "../create-salesforce.module.scss";

export const CreateSalesforceBusinessUnit: FC<CreateSalesforceAccountProps> = ({ isOpen, close, onCreateSuccess }) => {
  const { submit, error, onSubmitSuccess, onSubmitError } = useSubmit(createBusinessUnit);
  onSubmitSuccess(({ response }) => {
    onCreateSuccess(response);
    close();
    notification.success("Salesforce Business Unit created!", "Business Unit has been successfully created.");
  });
  onSubmitError(() => {
    notification.error("Create error!", "Error occurred while creating the Business Unit.");
  });

  const handleSubmit = async (values: CreateBusinessUnitFormData) => {
    const prepareAuthUrl = (value: string) => {
      if (value.endsWith("/")) return value;
      return `${value}/`;
    };

    const data: CreateBusinessUnitData = {
      ...values,
      accountId: +values.accountId,
      authUrl: prepareAuthUrl(values.authUrl),
    };
    await submit({ data: { ...data, accountId: +values.accountId } });
  };

  return (
    <Modal isOpen={isOpen} setClose={close}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ isValid }) => {
          return (
            <Form>
              <div className={styles.title}>Add Salesforce integration</div>
              <MessageField message={error?.message || ""} />
              <div className={styles.row}>
                <FormField type="text" name="name" label="Account name" disableFloatingLabel required />
                <FormField type="text" name="authUrl" label="Auth URL" disableFloatingLabel required />
              </div>
              <div className={styles.row}>
                <FormField type="text" name="clientId" label="Client ID" disableFloatingLabel required />
                <FormField type="text" name="clientSecret" label="Client secret" disableFloatingLabel required />
              </div>
              <FormField
                type="text"
                inputMode="numeric"
                name="accountId"
                label="Account ID"
                pattern="^\d+$"
                disableFloatingLabel
                required
              />

              <Hint type="info" className={styles.hint}>
                <Label text="Data extensions" isInputLabel />
                <p className={styles.salesforceLabel}>
                  You will be able to add Data Extensions in the next step after creating the Business Unit.
                </p>
              </Hint>

              <div className={styles.buttons}>
                <Button appearance="secondary" onClick={close}>
                  Cancel
                </Button>
                <Button type="submit" disabled={!isValid}>
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
