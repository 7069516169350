import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Suppressions file upload</h2>
    <p>
      The file upload field lets you drag and drop a CSV file containing the emails for suppression. Once the file is
      uploaded, you can select the column that contains the email addresses.
    </p>
    <p>
      Files can contain up to <strong>10 000</strong> emails, and this method streamlines the process for handling large
      batches of suppressions efficiently.
    </p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".fileUpload__container", on: "bottom-start" },
    permission: ["create", "suppressions"],
  },
];
