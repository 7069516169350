import { FC } from "react";

import { LoginSlider } from "../login-slider/login-slider";

import styles from "./login-sidebar.module.scss";

export const LoginSidebar: FC = () => {
  return (
    <div className={styles.sidebar}>
      <div className={styles.content}>
        <LoginSlider />
        <div className={styles.info}>
          <div className={styles.option}>&#169;{new Date().getFullYear()} Heiser</div>
        </div>
      </div>
    </div>
  );
};
