import { client } from "api/client.api";
import { IterableAccountModel, List } from "models";
import {
  CreateIterableAccountData,
  AccountResponse,
  GetAccountsQuery,
  UpdateIterableAccountData,
  CreateIterableAccountWithUpload,
} from "./accounts.types";
import { DeleteResponse, ServerErrorType } from "api/api.types";
import { fileUploadMiddleware } from "api/file-upload.api.middleware";

export const creteAccount = client.createRequest<AccountResponse, CreateIterableAccountData>()({
  method: "POST",
  endpoint: "/accounts",
});

export const createAccountWithFile = fileUploadMiddleware<undefined, CreateIterableAccountWithUpload>()({
  method: "post",
  endpoint: "/accounts/form",
  isFormData: true,
});

export const getAccounts = client.createRequest<List<AccountResponse>, null, ServerErrorType, GetAccountsQuery>()({
  method: "GET",
  endpoint: "/accounts",
  options: { timeout: 0 },
});

export const getActiveAccounts = client.createRequest<AccountResponse[], null, ServerErrorType, GetAccountsQuery>()({
  method: "GET",
  endpoint: "/accounts/active",
  options: { timeout: 0 },
});

export const getAccount = client.createRequest<IterableAccountModel>()({
  method: "GET",
  endpoint: "/accounts/:accountId",
  options: { timeout: 0 },
});

export const updateAccount = client.createRequest<AccountResponse, UpdateIterableAccountData>()({
  method: "PUT",
  endpoint: "/accounts/:accountId",
});

export const deleteAccount = client.createRequest<DeleteResponse>()({
  endpoint: "/accounts/:accountId",
  method: "DELETE",
});
