import React from "react";
import { Search, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";
import { useFetch } from "@hyper-fetch/react";
import { useDidUpdate } from "@better-hooks/lifecycle";
import { useDebounce } from "@better-hooks/performance";

import { getApiDataExtensions } from "api";
import { BasePaginationQuery, BaseSearchQuery } from "types";
import { getInitialStorageFilters } from "utils";
import { initialFilters, dataExtensionsColumns, TABLE_NAME } from "./data-extensions-creator.constants";
import { DataExtensionItem } from "./table-item/data-extension-item";
import { useTablePagination } from "hooks";

import styles from "./data-extensions-creator.module.scss";

interface Props {
  businessUnitId: number;
  isOpen: boolean;
}

export function DataExtensionsCreator({ businessUnitId, isOpen }: Props) {
  const { debounce } = useDebounce({ delay: 1200 });
  const { state, currentState, setValue } = useFilters<BasePaginationQuery & BaseSearchQuery>({
    ...getInitialStorageFilters<BasePaginationQuery & BaseSearchQuery>(TABLE_NAME, initialFilters),
    isStateBased: true,
  });

  const { data, error, loading, refetch } = useFetch(
    getApiDataExtensions.setQueryParams({ businessUnitId: businessUnitId.toString(), ...state }),
    { disabled: !isOpen },
  );
  const pagination = usePagination({
    listPayload: data,
    isStateBased: true,
    initialState: currentState,
  });
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange: pagination.onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  useDidUpdate(() => {
    setValue("offset")(pagination.state.offset);
  }, [pagination.state.offset]);

  useDidUpdate(() => {
    setValue("limit")(pagination.state.limit);
  }, [pagination.state.limit]);

  return (
    <div className={styles.wrapper}>
      <div>
        <Search
          className={styles.search}
          searchValue={state.search}
          setSearch={(value) => {
            debounce(() => setValue("search")(value));
          }}
        />

        <Table
          entityName={TABLE_NAME}
          columns={dataExtensionsColumns}
          list={data?.data || []}
          error={error?.message}
          refresh={refetch}
          loading={loading}
          resetColumnsOnMount={false}
          contentClassName={styles.listContent}
          className={styles.list}
          pagination={{
            ...pagination,
            elementsPerPage: currentElementsPerPage,
            onElementsPerPageChange: handlePerPageChange,
          }}
          row={(dataExtension, index) => <DataExtensionItem index={index} dataExtension={dataExtension} />}
        />
      </div>
    </div>
  );
}
