import { object, string, Schema, array, boolean } from "yup";

import { CreateIterableAccountData, IterableProjectData } from "api";

export const validationSchema: Schema<CreateIterableAccountData> = object().shape({
  name: string().required("Name is required"),
  proxy: string()
    .matches(/(http|https):\/\/(\S+):(\S+)@(\S+):(\d+)/gm, "Proxy should match the proper format")
    .required("Proxy is required"),
  owner: string().required("Owner is required"),
  globalSwitch: object().shape({
    enableUnforget: boolean().required(), // only for csv projects import
    enableComplaintsForwarding: boolean().required(), // only for csv projects import
    mergexImport: boolean().required(), // only for csv projects import
  }),
  projects: array()
    .of(
      object().shape({
        name: string().required("Project name is required"),
        apiKey: string().required("API key is required"),
        enableUnforget: boolean().required("This field is required"),
        enableComplaintsForwarding: boolean().required("This field is required"),
        mergexImport: boolean().required("This field is required"),
      }),
    )
    .required(),
});

export const emptyProject: IterableProjectData = {
  name: "",
  apiKey: "",
  enableUnforget: false,
  enableComplaintsForwarding: false,
  mergexImport: false,
};

export const initialValues: CreateIterableAccountData = {
  name: "",
  proxy: "",
  owner: "",
  globalSwitch: {
    enableUnforget: false, // only for csv projects import
    enableComplaintsForwarding: false, // only for csv projects import
    mergexImport: false, // only for csv projects import
  },
  projects: [],
};
