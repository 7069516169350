import { FC, useState } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { Formik, Form, FormikProps } from "formik";
import {
  Button,
  MessageField,
  FormButtons,
  FormField,
  notification,
  Modal,
  Label,
  Switch,
} from "@epcnetwork/core-ui-kit";

import { UpdateOfferData, updateOffer } from "api";
import { UpdateOptizmoOfferProps } from "./update-optizmo-offer.types";
import { validationSchema, initialValues } from "./update-optizmo-offer.constants";

import styles from "../update-optizmo.module.scss";

export const UpdateOptizmoOffer: FC<UpdateOptizmoOfferProps> = ({ isOpen, offer, close, onUpdateSuccess, company }) => {
  const [accessKeyEnabled, setAccessKeyEnabled] = useState<boolean>(false);

  const { submit, error, onSubmitSuccess, onSubmitError } = useSubmit(updateOffer);
  onSubmitSuccess(({ response }) => {
    onUpdateSuccess(response);
    close();
    notification.success("Optizmo offer updated!", "Offer has been successfully updated.");
  });
  onSubmitError(() => {
    notification.error("Update error!", "Error occurred while updating the offer");
  });

  const handleSwitchToggle =
    (formikProps: FormikProps<Partial<Pick<UpdateOfferData, "name" | "accessKey" | "isActive">>>) =>
    (value: string, checked: boolean) => {
      setAccessKeyEnabled(checked);
      if (!checked) {
        formikProps.setFieldValue("accessKey", "");
        formikProps.setFieldTouched("accessKey", false, false);
      }
    };

  const handleSubmit = async (values: UpdateOfferData) => {
    if (offer) {
      const { accessKey, ...rest } = values;
      const data = accessKeyEnabled ? values : rest;
      await submit({ data, params: { offerId: offer.id } });
    }
  };

  const accessKeyNotEnabledPlaceholder = offer?.optizmo ? "Use the same access key" : "Do not configure access key";

  return (
    <Modal isOpen={isOpen} setClose={close}>
      <Formik
        initialValues={initialValues(offer)}
        onSubmit={handleSubmit}
        validationSchema={validationSchema(accessKeyEnabled)}
        enableReinitialize
      >
        {(props) => (
          <Form>
            <div className={styles.title}>Update {offer?.name} offer</div>
            <MessageField message={error?.message || ""} />

            <FormField type="text" name="name" label="Offer name" disableFloatingLabel required />

            <div>
              <div>
                <div className={styles.labelRow}>
                  <Label text="Access key" isInputLabel />
                  <Switch
                    value="test"
                    disableError
                    checked={accessKeyEnabled}
                    onChange={handleSwitchToggle(props)}
                    disabled={!company?.optizmo}
                  />
                </div>
                {!company?.optizmo && (
                  <p className={styles.projectDescription}>
                    Company was not configured to use Optizmo. You cannot edit access token.
                  </p>
                )}
              </div>
              <FormField
                type="text"
                name="accessKey"
                placeholder={accessKeyEnabled ? "Provide a new access key" : accessKeyNotEnabledPlaceholder}
                disableFloatingLabel
                disabled={!accessKeyEnabled || !company?.optizmo}
              />
            </div>

            <FormButtons className={styles.buttons}>
              <Button appearance="secondary" onClick={close}>
                Cancel
              </Button>
              <Button type="submit">Submit</Button>
            </FormButtons>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
