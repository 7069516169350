import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Specific queue page</h2>
    <p>Here, you can dive deeper into the specifics of each suppression queue.</p>
    <p>
      At the top, you’ll find basic information about the job, including the creator&apos;s name and the date the task
      was initiated, giving you important context for tracking and auditing purposes.
    </p>

    <p>
      Below, you’ll see a comprehensive list of all emails that were included in this suppression. This allows you to
      review exactly which suppressions have been processed, ensuring that your lists are up-to-date and accurate.
    </p>
  </div>
);

const secondStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Queue emails table</h2>

    <p>
      This page displays a list of emails involved in the suppression task, along with their individual statuses. For
      each email, you can see whether the suppression is <strong>In Progress</strong>, <strong>Finished</strong> or{" "}
      <strong>Failed</strong> giving you a clear picture of how each contact was processed.
    </p>

    <p>
      You can also click on any email in the list to open up a detailed modal. In this modal, you’ll find more in-depth
      information about the suppression status for that specific email, broken down by each project in Iterable or data
      extension in Salesforce. This allows you to pinpoint exactly where each email was processed and its outcome for
      every associated list.
    </p>

    <p>
      This detailed view helps you monitor and troubleshoot any issues with suppressions on an individual email level.
    </p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".job-details-navbar", on: "bottom-start" },
    permission: ["read", "jobs"],
  },
  {
    text: renderToString(secondStep),
    attachTo: { element: ".table", on: "top" },
    permission: ["read", "jobs"],
  },
];
