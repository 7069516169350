import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { ColumnsType } from "types";
import { GetUsersQuery } from "api";
import { SEARCH_MIN_LENGTH } from "constants/form.constants";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "constants/query.constants";

export const whitelistUsersColumns: ColumnsType = [
  { label: "Email", required: true, queryKey: "email" },
  { label: "Created at", queryKey: "createdAt" },
  { label: "Account status" },
  { label: "" },
];

export const TABLE_NAME = "whitelist-table";

export const initialFilters: UseFiltersProps<GetUsersQuery> = {
  searchOptions: { searchMinLength: SEARCH_MIN_LENGTH, searchDebounce: 1200, searchKey: "search" },
  initialState: {
    search: "",
    limit: DEFAULT_LIMIT,
    offset: DEFAULT_OFFSET,
  },
};
