import React, { useState } from "react";
import { useSubmit } from "@hyper-fetch/react";
import { FormField, Label, SelectOption } from "@epcnetwork/core-ui-kit";
import { useFormikContext } from "formik";
import { useDidMount, useDidUpdate } from "@better-hooks/lifecycle";

import { getActiveBusinessUnits } from "api";
import { OptizmoExportData, SalesforceData } from "pages/md5/export/md5-export.types";
import { initialSalesforceDataExtensionsData, initialSalesforceListsData } from "pages/md5/export/md5-export.constants";
import { TabOption, Tabs } from "components";
import { DataExtensions } from "pages/md5/export/esp-selector/salesforce/data-extensions/data-extensions";
import { Lists } from "pages/md5/export/esp-selector/salesforce/lists/lists";

import styles from "pages/md5/export/md5-export.module.scss";

export const SalesforceSelector: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<OptizmoExportData>();

  const [businessUnitsOptions, setBusinessUnitsOptions] = useState<SelectOption<number>[]>([]);

  const espData = values.espData as SalesforceData;

  const activeBusinessUnits = useSubmit(getActiveBusinessUnits);
  activeBusinessUnits.onSubmitSuccess(({ response }) => {
    const options: SelectOption<number>[] = response.map((businessUnits) => ({
      label: businessUnits.name,
      value: businessUnits.id,
    }));
    setBusinessUnitsOptions(options);
  });

  useDidMount(() => {
    activeBusinessUnits.submit();
  });

  useDidUpdate(() => {
    // reset fromList and toList based on listType
    if (espData.type === "dataExtensions") setFieldValue("espData.data", initialSalesforceDataExtensionsData);
    if (espData.type === "lists") setFieldValue("espData.data", initialSalesforceListsData);
  }, [espData.businessUnitId]);

  const handleTabChange = async ({ value }: TabOption<"dataExtensions" | "lists">) => {
    await setFieldValue("espData.type", value);
    if (value === "dataExtensions") await setFieldValue("espData.data", initialSalesforceDataExtensionsData);
    if (value === "lists") await setFieldValue("espData.data", initialSalesforceListsData);
  };

  const options: TabOption<"dataExtensions" | "lists">[] = [
    { label: "Data extensions", value: "dataExtensions" },
    { label: "Lists", value: "lists" },
  ];

  return (
    <div style={{ marginTop: "26px" }}>
      <div>
        <div className={styles.row}>
          <div className={styles.step}>5</div>
          <div>
            <Label text="Business unit" isInputLabel />
            <p className={styles.projectDescription}>Select a business unit</p>
          </div>
        </div>
        <FormField
          type="select"
          name="espData.businessUnitId"
          options={businessUnitsOptions}
          isSearchable
          asyncOptions={{
            loading: activeBusinessUnits.submitting,
          }}
          searchPlaceholder="Search accounts"
          isMulti={false}
        />
      </div>

      <div>
        <div className={styles.row}>
          <div className={styles.step}>6</div>
          <div>
            <Label text="Salesforce data type" isInputLabel />
            <p className={styles.projectDescription}>You can export data between lists and data extensions</p>
          </div>
        </div>
        <Tabs selected={espData.type || "dataExtensions"} onChange={handleTabChange} options={options} />
      </div>

      {espData.type === "dataExtensions" && <DataExtensions />}
      {espData.type === "lists" && <Lists />}
    </div>
  );
};
