import React, { FC, useState } from "react";
import { useQuery } from "@epcnetwork/core-ui-kit";

import { useSidebar } from "hooks";
import { Container, TabOption, Tabs } from "components";
import { Navbar } from "components/layout/navbar/navbar";
import { UsersList } from "./users/users-list";
import { Whitelist } from "./whitelist/whitelist";

import styles from "./users-list.module.scss";

type UsersView = "users" | "whitelist";

export const UsersListPage: FC = () => {
  const { setQueryParams } = useQuery();
  const { isMenuCollapsed, setMenuCollapsed } = useSidebar();

  const [view, setView] = useState<UsersView>("users");

  const roleOptions: TabOption<UsersView>[] = [
    { value: "users", label: "Users", className: "users-tab" },
    { value: "whitelist", label: "Whitelist", className: "whitelist-tab" },
  ];

  const handleTabClick = (view: TabOption<UsersView>) => {
    setQueryParams({ search: "" });
    setView(view.value);
  };

  const toggleSidebar = () => setMenuCollapsed(!isMenuCollapsed);

  const pageName = view === "users" ? "Users" : "Whitelist";
  const pageDescription =
    view === "users"
      ? "List of all Heiser users. User accounts are created when email is on whitelist and user has logged into their account for the first time."
      : "List of emails allowed to access Heiser.";

  return (
    <>
      <Navbar
        isMenuCollapsed={isMenuCollapsed}
        toggleSidebar={toggleSidebar}
        name={pageName}
        description={pageDescription}
        className={styles.navbar}
        navbarClassName="users-list-navbar"
      />
      <Container>
        <div className={styles.tabsWrapper}>
          <Tabs options={roleOptions} selected={view} onChange={handleTabClick} className={styles.tabs} />
        </div>

        {view === "users" && <UsersList whitelistClick={() => setView("whitelist")} />}
        {view === "whitelist" && <Whitelist />}
      </Container>
    </>
  );
};
