import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type InitialState = {
  changelogOpen: boolean;
  globalLoading: boolean;
};

const initialState: InitialState = {
  changelogOpen: false,
  globalLoading: true,
};

const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    resetAppState: (state) => {
      state.changelogOpen = false;
    },
    setChangelogOpen: (state, action: PayloadAction<boolean>) => {
      state.changelogOpen = action.payload;
    },
    setGlobalLoading: (state, action: PayloadAction<boolean>) => {
      state.globalLoading = action.payload;
    },
  },
});

export const { resetAppState, setChangelogOpen, setGlobalLoading } = app.actions;

export default app.reducer;
