import React from "react";
import { renderToString } from "react-dom/server";

import { TourStepOption } from "hooks";

const firstStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>MD5 import page</h2>
    <p>
      The Import page is your central location for uploading CSV files containing MD5 hashes of emails to be imported.
      This page makes it easy to keep your email lists up to date and compliant with your system requirements.
    </p>
  </div>
);

const secondStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Select an Optizmo offer</h2>
    <p>
      To begin, select the target offer by choosing a company and client, then select the specific offer associated with
      the email list you want to update.
    </p>
  </div>
);

const thirdStep = (
  <div>
    <h2 style={{ marginTop: 0 }}>Upload CSV with MD5</h2>
    <p>
      After selecting the offer, simply upload your CSV file containing the MD5 hashes of emails. Once uploaded, the
      system will process the data, adding the emails to the specified offer for seamless list management.
    </p>
  </div>
);

export const steps: TourStepOption[] = [
  {
    text: renderToString(firstStep),
    attachTo: { element: ".md5-import-navbar", on: "bottom-start" },
    permission: ["create", "md5"],
  },
  {
    text: renderToString(secondStep),
    attachTo: { element: ".offer-selector", on: "top" },
    permission: ["create", "md5"],
  },
  {
    text: renderToString(thirdStep),
    attachTo: { element: ".md5-file-import", on: "top" },
    permission: ["create", "md5"],
  },
];
